/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import _ from 'lodash';
import { default_http_forward_action_data, default_response_action, virtualServiceMetaData, SV_SCRIPT_ENGINE_URL } from './constants';


// Set default values in virtual service if something is not present,
// for example, if requestType is not present make it HTTP, if request.query is not present add a default query matcher
const processVirtualService = (virtualService) => {
	let processedVirtualService = _.cloneDeep(virtualService);
	processedVirtualService.data.pairs = processedVirtualService.data.pairs.map(
		(item) => {
			let enhancedItem = _.cloneDeep(item);
			if (!('label' in enhancedItem)) {
				enhancedItem.label = '';
			}
			if (!('requestType' in enhancedItem.request)) {
				// If requestType is not present assume it is HTTP request
				enhancedItem.request.requestType = 'HTTP';
			}
			// Here we can be sure we have requestType in request and you are currently mutating enhancedItem
			switch (enhancedItem.request.requestType) {
				// HTTP need not be considered because we don't need to mutate HTTP
				case 'JMS': {
					if (enhancedItem.request.path[0].value.toString().includes('/topic/')) {
						enhancedItem.request.path[0].jsmType = 'topic';
						enhancedItem.request.path[0].value = enhancedItem.request.path[0].value.toString().replace('/_jms/topic/', '');
					}
					else {
						enhancedItem.request.path[0].jsmType = 'queue';
						enhancedItem.request.path[0].value = enhancedItem.request.path[0].value.toString().replace('/_jms/', '');
					}
					break;
				}
				case 'Tibco': {
					if (enhancedItem.request.path[0].value.toString().includes('/topic/')) {
						enhancedItem.request.path[0].tibcoType = 'topic';
						enhancedItem.request.path[0].value = enhancedItem.request.path[0].value.toString().replace('/_tibco/topic/', '');
					}
					else {
						enhancedItem.request.path[0].tibcoType = 'queue';
						enhancedItem.request.path[0].value = enhancedItem.request.path[0].value.toString().replace('/_tibco/', '');
					}
					break;
				}
				case 'CometD': {
					enhancedItem.request.path[0].value = enhancedItem.request.path[0].value.toString().replace('/_cometd/', '');
					break;
				}
				case 'Kinesis': {
					enhancedItem.request.path[0].value = enhancedItem.request.path[0].value.toString().replace('/_kinesis/', '');
					break;
				}
				case 'SQS': {
					enhancedItem.request.path[0].value = enhancedItem.request.path[0].value.toString().replace('/_sqs/', '');
					break;
				}
				case 'IBMMQ': {
					enhancedItem.request.path[0].value = enhancedItem.request.path[0].value.toString().replace('/_ibmmq/', '');
					break;
				}
			}
			if (
				!('query' in enhancedItem.request) ||
				!Object.keys(enhancedItem.request.query).length
			) {
				enhancedItem.request.query = {
					'': [{ value: '', matcher: 'exact' }],
				};
			}
			if ('Svhost' in enhancedItem.request.headers) {
				delete enhancedItem.request.headers.Svhost;
			}
			// If no length for headers, add an empty header
			if (!Object.keys(enhancedItem.request.headers).length) {
				enhancedItem.request.headers = {
					'': [{ value: '', matcher: 'exact' }],
				};
			}
			if (
				!('body' in enhancedItem.request) ||
				!enhancedItem.request.body.length
			) {
				enhancedItem.request.body = [{ value: '', matcher: 'exact' }];
			}
			if (
				!('headers' in enhancedItem.response) ||
				!Object.keys(enhancedItem.response.headers).length
			) {
				enhancedItem.response.headers = { '': [''] };
			}
			if ('actions' in enhancedItem.response && enhancedItem.response.actions.length) {
				enhancedItem.response.areResponseActionsEnabled = true;
				const ensureActionPropsAndFormat = (action) => {
					if (('params' in action.actionData) && !(_.isEmpty(action.actionData.params))) {
						let newParams = [];
						Object.entries(action.actionData.params).map(([pkey, pvalue]) => {
							newParams.push({ querykey: pkey, value: pvalue });
						});
						action.actionData.params = newParams;
					}
					else if (!('params' in action.actionData) || (_.isEmpty(action.actionData.params))) {
						action.actionData.params = [{ querykey: '', value: '' }];
					}
					if (('headers' in action.actionData) && !(_.isEmpty(action.actionData.headers))) {
						let newHeaders = [];
						Object.entries(action.actionData.headers).map(([hkey, hvalue]) => {
							newHeaders.push({ headerkey: hkey, value: hvalue[0] });
						});
						action.actionData.headers = newHeaders;
					}
					else if (!('headers' in action.actionData) || (_.isEmpty(action.actionData.headers))) {
						action.actionData.headers = [{ headerkey: '', value: '' }];
					}
					if (!('body' in action.actionData)) {
						action.actionData.body = '';
					}
					return action;
				};
				enhancedItem.response.actions = enhancedItem.response.actions.map((action) => {
					let clonedAction = _.cloneDeep(action);
					if (!('templated' in clonedAction)) {
						clonedAction.templated = false;
					}
					if (!('condition' in clonedAction)) {
						clonedAction.condition = '';
					}
					if (!('synchronous' in clonedAction)) {
						clonedAction.synchronous = true;
					}
					if (!('forEach' in clonedAction)) {
						clonedAction.forEach = '';
					}
					if ('delaySeconds' in clonedAction && clonedAction.delaySeconds) {
						clonedAction.delay = true;
					}
					switch (clonedAction.actionType) {
						case 'tibcoWriteTopic':
						case 'tibcoWriteQueue':
						case 'jmsWriteTopic':
						case 'jmsWriteQueue':
						case 'ibmmqWriteQueue':
						case 'sqsQueue': {
							const httpURL = clonedAction.actionData.httpUrl.toString();
							clonedAction.actionData.protocolProxyURL = httpURL.substring(0, httpURL.indexOf('/proxy'));
							clonedAction.actionData.qOrTName = httpURL.substring(httpURL.lastIndexOf('/') + 1,);
							clonedAction.actionData.qOrTMessage = clonedAction.actionData.body;
							if (clonedAction.actionData.headers && clonedAction.actionData.headers['Authorization']) {
								clonedAction.actionData.authorization = clonedAction.actionData.headers['Authorization'][0];
								delete clonedAction.actionData.headers['Authorization'];
							}
							if (clonedAction.actionData.headers && clonedAction.actionData.headers['content-type']) {
								clonedAction.actionData.contentType = clonedAction.actionData.headers['content-type'][0];
								delete clonedAction.actionData.headers['content-type'];
							}
							delete clonedAction.actionData.httpUrl;
							delete clonedAction.actionData.body;
							break;
						}
						case 'kinesisWrite': {
							const httpURL = clonedAction.actionData.httpUrl.toString();
							clonedAction.actionData.protocolProxyURL = httpURL.substring(0, httpURL.indexOf('/proxy'));
							clonedAction.actionData.streamName = httpURL.substring(httpURL.lastIndexOf('/') + 1,);
							clonedAction.actionData.streamMessage = clonedAction.actionData.body;
							clonedAction.actionData.partitionKey = clonedAction.actionData.headers['PartitionKey'][0];
							if (clonedAction.actionData.headers['Authorization']) {
								clonedAction.actionData.authorization = clonedAction.actionData.headers['Authorization'][0];
							}
							if (clonedAction.actionData.headers && clonedAction.actionData.headers['content-type']) {
								clonedAction.actionData.contentType = clonedAction.actionData.headers['content-type'][0];
							}
							delete clonedAction.actionData.httpUrl;
							delete clonedAction.actionData.body;
							break;
						}
						case 'executeJS': {
							clonedAction.actionData.script = clonedAction.actionData.body;
							if (clonedAction.actionData.headers && clonedAction.actionData.headers['content-type']) {
								clonedAction.actionData.contentType = clonedAction.actionData.headers['content-type'][0];
							}
							delete clonedAction.actionData.httpUrl;
							delete clonedAction.actionData.body;
							break;
						}
						case 'database': {
							const httpURL = clonedAction.actionData.httpUrl.toString();
							clonedAction.actionData.protocolProxyURL = httpURL.substring(0, httpURL.indexOf('/database'));
							clonedAction.actionData.activityType = httpURL.substring(httpURL.lastIndexOf('/') + 1,);
							clonedAction.actionData.query = clonedAction.actionData.body;
							if (clonedAction.actionData.headers && clonedAction.actionData.headers['Authorization']) {
								clonedAction.actionData.authorization = clonedAction.actionData.headers['Authorization'][0];
							}
							if (clonedAction.actionData.headers && clonedAction.actionData.headers['content-type']) {
								clonedAction.actionData.contentType = clonedAction.actionData.headers['content-type'][0];
							}
							delete clonedAction.actionData.httpUrl;
							delete clonedAction.actionData.body;
							break;
						}
					}
					clonedAction = ensureActionPropsAndFormat(clonedAction);
					return clonedAction;
				});
			}
			if (
				!('actions' in enhancedItem.response) ||
				!enhancedItem.response.actions.length
			) {
				enhancedItem.response.actions = [
					_.cloneDeep(default_response_action),
				];
			}
			return enhancedItem;
		}
	);
	return processedVirtualService;
};

// Collates endpointpairs to convert to schema format
const collateVirtualService = (endpointPairs, sVHostHeader) => {
	let endpointPairsClone = _.cloneDeep(endpointPairs);
	let virtualService = { ...virtualServiceMetaData, data: { pairs: endpointPairsClone } };
	virtualService.data.pairs = virtualService.data.pairs.map((pair) => {
		let newPair = _.cloneDeep(pair);
		if (!newPair.label) {
			delete newPair.label;
		}
		switch (newPair.request.requestType) {
			case 'JMS': {
				newPair.request.method[0].value = 'POST';
				if (newPair.request.path[0].jsmType === 'topic') {
					newPair.request.path[0].value = `/_jms/topic/${newPair.request.path[0].value}`;
				}
				else {
					newPair.request.path[0].value = `/_jms/${newPair.request.path[0].value}`;
				}
				break;
			}
			case 'Tibco': {
				newPair.request.method[0].value = 'POST';
				if (newPair.request.path[0].tibcoType === 'topic') {
					newPair.request.path[0].value = `/_tibco/topic/${newPair.request.path[0].value}`;
				}
				else {
					newPair.request.path[0].value = `/_tibco/${newPair.request.path[0].value}`;
				}
				break;
			}
			case 'CometD': {
				newPair.request.method[0].value = 'POST';
				newPair.request.path[0].value = `/_cometd/${newPair.request.path[0].value}`;
				break;
			}
			case 'Kinesis': {
				newPair.request.method[0].value = 'POST';
				newPair.request.path[0].value = `/_kinesis/${newPair.request.path[0].value}`;
				break;
			}
			case 'SQS': {
				newPair.request.method[0].value = 'POST';
				newPair.request.path[0].value = `/_sqs/${newPair.request.path[0].value}`
				break;
			}
			case 'IBMMQ': {
				newPair.request.method[0].value = 'POST';
				newPair.request.path[0].value = `/_ibmmq/${newPair.request.path[0].value}`
				break;
			}
		}
		newPair.request.headers.Svhost = [
			{ value: sVHostHeader, matcher: 'glob' },
		];
		delete newPair.tableData;
		delete newPair.request.headers[''];
		delete newPair.request.query[''];
		delete newPair.request.path[0].jsmType;
		delete newPair.request.path[0].tibcoType;

		if (newPair.request.body[0].value === '') {
			newPair.request.body = [];
		}
		delete newPair.response.headers[''];
		//if ('fixedDelay' in newPair.response && newPair.response.fixedDelay === 0) {
		//	delete newPair.response.fixedDelay;
		//} else 
		if ('fixedDelay' in newPair.response) {
			//newPair.response.fixedDelay = parseInt(newPair.response.fixedDelay);
			newPair.response.fixedDelay = newPair.response.fixedDelay;
		}else{
			delete newPair.response.fixedDelay;
		}
		if (
			(newPair.response.actions.length === 1 &&
				(_.isEqual(newPair.response.actions[0], default_response_action) || (_.isEqual(newPair.response.actions[0].actionData, default_http_forward_action_data))))
			|| !newPair.response.areResponseActionsEnabled
		) {
			// Delete actions if there is only one action and (is the default one or the actionData is the default one)
			delete newPair.response.actions;
		} else {
			newPair.response.actions = newPair.response.actions
				// Filter only those actions which are not the (default ones or actionData is default one)
				.filter((action) => ((!_.isEqual(action, default_response_action)) || (!_.isEqual(action.actionData, default_http_forward_action_data))))
				// TODO After filtering process each action to add actionId, transform query, headers into schema format
				.map((action, index) => {
					let newAction = _.cloneDeep(action);
					newAction.actionId = `action${index}`;
					if (!(newAction.templated)) {
						delete newAction.templated;
					}
					if (!(newAction.condition)) {
						delete newAction.condition;
					}
					if (newAction.synchronous) {
						delete newAction.synchronous;
						delete newAction.delay;
						delete newAction.delaySeconds;
					}
					if (!newAction.delay || !newAction.delaySeconds) {
						delete newAction.delaySeconds;
					}
					delete newAction.delay;
					if (!(newAction.forEach)) {
						delete newAction.forEach;
					}
					// Headers and params handle
					if (_.isEqual(newAction.actionData.headers, default_http_forward_action_data.headers)) {
						delete newAction.actionData.headers;
					}
					else {
						let newHeaders = {};
						newAction.actionData.headers.filter((header) => {
							if ((_.isEqual(header, default_http_forward_action_data.headers[0])) || !(header.headerkey)) {
								return false;
							}
							else {
								return true;
							}
						}).map((header) => {
							newHeaders[header.headerkey] = [header.value];
						});
						if (!(newAction.actionData.headers.length)) {
							delete newAction.actionData.headers;
						}
						else {
							newAction.actionData.headers = newHeaders;
						}
					}
					if (_.isEqual(newAction.actionData.params, default_http_forward_action_data.params)) {
						delete newAction.actionData.params;
					}
					else {
						let newParams = {};
						newAction.actionData.params.filter((param) => {
							if ((_.isEqual(param, default_http_forward_action_data.params[0])) || !(param.querykey)) {
								return false;
							}
							else {
								return true;
							}
						}).map((param) => {
							newParams[param.querykey] = param.value;
						});
						if (!(newAction.actionData.params.length)) {
							delete newAction.actionData.params;
						}
						else {
							newAction.actionData.params = newParams;
						}
					}
					switch (newAction.actionType) {
						case 'httpForward': {
							if (!(newAction.actionData.body)) {
								delete newAction.actionData.body;
							}
							break;
						}
						case 'jmsWriteTopic': {
							newAction.actionData.httpUrl = `${newAction.actionData.protocolProxyURL}/proxy/jms/topic/${newAction.actionData.qOrTName}`;
							newAction.actionData.httpMethod = 'POST';
							newAction.actionData.body = newAction.actionData.qOrTMessage;
							if (!newAction.actionData.headers) {
								newAction.actionData.headers = {};
							}
							if (newAction.actionData.authorization) {
								newAction.actionData.headers['Authorization'] = [newAction.actionData.authorization];
							}
							if (newAction.actionData.contentType) {
								newAction.actionData.headers['content-type'] = [newAction.actionData.contentType];
							}
							delete newAction.actionData.contentType;
							delete newAction.actionData.authorization;

							if (_.isEmpty(newAction.actionData.headers)) {

								delete newAction.actionData.headers;
							}
							delete newAction.actionData.protocolProxyURL;
							delete newAction.actionData.qOrTName;
							delete newAction.actionData.qOrTMessage;
							delete newAction.actionData.params;
							break;
						}
						case 'jmsWriteQueue': {
							newAction.actionData.httpUrl = `${newAction.actionData.protocolProxyURL}/proxy/jms/queue/${newAction.actionData.qOrTName}`;
							newAction.actionData.httpMethod = 'POST';
							newAction.actionData.body = newAction.actionData.qOrTMessage;
							if (!newAction.actionData.headers) {
								newAction.actionData.headers = {};
							}
							if (newAction.actionData.authorization) {
								newAction.actionData.headers['Authorization'] = [newAction.actionData.authorization];
							}
							if (newAction.actionData.contentType) {
								newAction.actionData.headers['content-type'] = [newAction.actionData.contentType];
							}
							delete newAction.actionData.contentType;
							delete newAction.actionData.authorization;

							if (_.isEmpty(newAction.actionData.headers)) {
								delete newAction.actionData.headers;
							}
							delete newAction.actionData.protocolProxyURL;
							delete newAction.actionData.qOrTName;
							delete newAction.actionData.qOrTMessage;
							delete newAction.actionData.params;
							break;
						}
						case 'tibcoWriteTopic': {
							newAction.actionData.httpUrl = `${newAction.actionData.protocolProxyURL}/proxy/tibco/topic/${newAction.actionData.qOrTName}`;
							newAction.actionData.httpMethod = 'POST';
							newAction.actionData.body = newAction.actionData.qOrTMessage;
							if (!newAction.actionData.headers) {
								newAction.actionData.headers = {};
							}
							if (newAction.actionData.authorization) {
								newAction.actionData.headers['Authorization'] = [newAction.actionData.authorization];
							}
							if (newAction.actionData.contentType) {
								newAction.actionData.headers['content-type'] = [newAction.actionData.contentType];
							}
							delete newAction.actionData.contentType;
							delete newAction.actionData.authorization;

							if (_.isEmpty(newAction.actionData.headers)) {

								delete newAction.actionData.headers;
							}
							delete newAction.actionData.protocolProxyURL;
							delete newAction.actionData.qOrTName;
							delete newAction.actionData.qOrTMessage;
							delete newAction.actionData.params;
							break;
						}
						case 'tibcoWriteQueue': {
							newAction.actionData.httpUrl = `${newAction.actionData.protocolProxyURL}/proxy/tibco/queue/${newAction.actionData.qOrTName}`;
							newAction.actionData.httpMethod = 'POST';
							newAction.actionData.body = newAction.actionData.qOrTMessage;
							if (!newAction.actionData.headers) {
								newAction.actionData.headers = {};
							}

							if (newAction.actionData.authorization) {
								newAction.actionData.headers['Authorization'] = [newAction.actionData.authorization];
							}
							if (newAction.actionData.contentType) {
								newAction.actionData.headers['content-type'] = [newAction.actionData.contentType];
							}
							delete newAction.actionData.contentType;
							delete newAction.actionData.authorization;

							if (_.isEmpty(newAction.actionData.headers)) {
								delete newAction.actionData.headers;
							}
							delete newAction.actionData.protocolProxyURL;
							delete newAction.actionData.qOrTName;
							delete newAction.actionData.qOrTMessage;
							delete newAction.actionData.params;
							break;
						}
						case 'kinesisWrite': {
							newAction.actionData.httpUrl = `${newAction.actionData.protocolProxyURL}/proxy/kinesis/write/${newAction.actionData.streamName}`;
							newAction.actionData.httpMethod = 'POST';

							newAction.actionData.body = newAction.actionData.streamMessage;
							if (!newAction.actionData.headers) {
								newAction.actionData.headers = {};
							}
							let headerObjPartitionKey = [];
							headerObjPartitionKey.push(newAction.actionData.partitionKey);

							if (typeof newAction.actionData.partitionKey !== 'undefined') {
								newAction.actionData.headers['PartitionKey'] = headerObjPartitionKey;
							}
							else {
								newAction.actionData.headers['PartitionKey'] = ['undefined'];
							}
							if (newAction.actionData.authorization) {
								newAction.actionData.headers['Authorization'] = [newAction.actionData.authorization];
							}
							if (newAction.actionData.contentType) {
								newAction.actionData.headers['content-type'] = [newAction.actionData.contentType];
							}
							delete newAction.actionData.contentType;
							delete newAction.actionData.authorization;

							if (_.isEmpty(newAction.actionData.headers)) {
								delete newAction.actionData.headers;
							}
							delete newAction.actionData.protocolProxyURL;
							delete newAction.actionData.streamName;
							delete newAction.actionData.streamMessage;
							delete newAction.actionData.partitionKey;
							delete newAction.actionData.params;
							break;
						}
						case 'executeJS': {
							newAction.actionData.httpUrl = SV_SCRIPT_ENGINE_URL;
							newAction.actionData.httpMethod = 'POST';
							newAction.actionData.body = newAction.actionData.script;
							
							if (!newAction.actionData.headers) {
								newAction.actionData.headers = {};
							}
							newAction.actionData.headers['content-type'] = ['text/plain'];
							delete newAction.actionData.script;
							delete newAction.actionData.params;
							delete newAction.actionData.contentType;
							break;
						}
						case 'properties': {
							newAction.actionData = {
								body: newAction.actionData.body
							};
							newAction.templated = true;
							break;
						}
						case 'database': {
							if (!newAction.actionData.activityType) {
								newAction.actionData.activityType = 'executeQuery';
							}
							newAction.actionData.httpUrl = `${newAction.actionData.protocolProxyURL}/database/${newAction.actionData.activityType}`;
							newAction.actionData.httpMethod = 'POST';

							newAction.actionData.body = newAction.actionData.query;
							// Database action's header will be always set to text/plain, if needs to be changed refer to corresponding implementation for other action types
							newAction.actionData.headers = { 'content-type': ['text/plain'] };
							if (newAction.actionData.authorization) {
								newAction.actionData.headers['Authorization'] = [newAction.actionData.authorization];
							}
							delete newAction.actionData.contentType;
							delete newAction.actionData.authorization;
							if (_.isEmpty(newAction.actionData.headers)) {
								delete newAction.actionData.headers;
							}
							delete newAction.actionData.protocolProxyURL;
							delete newAction.actionData.activityType;
							delete newAction.actionData.query;
							delete newAction.actionData.params;
							break;
						}
						case 'sqsQueue': {
							newAction.actionData.httpUrl = `${newAction.actionData.protocolProxyURL}/proxy/sqs/queue/${newAction.actionData.qOrTName}`;
							newAction.actionData.httpMethod = 'POST';
							newAction.actionData.body = newAction.actionData.qOrTMessage;
							if (!newAction.actionData.headers) {
								newAction.actionData.headers = {};
							}
							if (newAction.actionData.authorization) {
								newAction.actionData.headers['Authorization'] = [newAction.actionData.authorization];
							}
							if (newAction.actionData.contentType) {
								newAction.actionData.headers['content-type'] = [newAction.actionData.contentType];
							}
							delete newAction.actionData.contentType;
							delete newAction.actionData.authorization;

							if (_.isEmpty(newAction.actionData.headers)) {
								delete newAction.actionData.headers;
							}
							delete newAction.actionData.protocolProxyURL;
							delete newAction.actionData.qOrTName;
							delete newAction.actionData.qOrTMessage;
							delete newAction.actionData.params;
							break;
						}
						case 'ibmmqWriteQueue': {
							newAction.actionData.httpUrl = `${newAction.actionData.protocolProxyURL}/proxy/ibmmq/queue/${newAction.actionData.qOrTName}`;
							newAction.actionData.httpMethod = 'POST';
							newAction.actionData.body = newAction.actionData.qOrTMessage;
							if (!newAction.actionData.headers) {
								newAction.actionData.headers = {};
							}
							if (newAction.actionData.authorization) {
								newAction.actionData.headers['Authorization'] = [newAction.actionData.authorization];
							}
							if (newAction.actionData.contentType) {
								newAction.actionData.headers['content-type'] = [newAction.actionData.contentType];
							}
							delete newAction.actionData.contentType;
							delete newAction.actionData.authorization;

							if (_.isEmpty(newAction.actionData.headers)) {
								delete newAction.actionData.headers;
							}
							delete newAction.actionData.protocolProxyURL;
							delete newAction.actionData.qOrTName;
							delete newAction.actionData.qOrTMessage;
							delete newAction.actionData.params;
							break;
						}
						case 'smtp': {
							newAction.actionData.httpMethod = 'POST';
							if (!newAction.actionData.headers) {
								newAction.actionData.headers = {};
							}
							if (newAction.actionData.authorization) {
								newAction.actionData.headers['Authorization'] = [newAction.actionData.authorization];
							}
							if (newAction.actionData.contentType) {
								newAction.actionData.headers['content-type'] = [newAction.actionData.contentType];
							}
							delete newAction.actionData.contentType;
							delete newAction.actionData.authorization;

							if (_.isEmpty(newAction.actionData.headers)) {
								delete newAction.actionData.headers;
							}
							delete newAction.actionData.protocolProxyURL;
							delete newAction.actionData.qOrTName;
							delete newAction.actionData.qOrTMessage;
							delete newAction.actionData.params;
							break;
						}
					}
					return newAction;
				});
		}
		delete newPair.response.areResponseActionsEnabled;
		return newPair;
	});
	return virtualService;
};

export {
	processVirtualService,
	collateVirtualService,
};
