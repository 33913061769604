import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { AddBox, Publish, Info, Settings } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import MaterialTable from "material-table";
import withReactContent from 'sweetalert2-react-content'
import { Typography, Grid } from "@material-ui/core";
import simulationService from "../../services/simulation.service";
import LibraryService from "../../services/library.service";
import Preview from "./Preview";
import UploadNewStub from "./UploadNewStub";
import { swalBootstrapButtonsQuestion, swalBootstrapButtonsDangerousQuestion } from "../utils/swal";
import { palette } from "../../assets/colors"
import InfoDialog from "./InfoDialog"
import SettingsComp from "./Settings"
import workspaceSettingsService from "../../services/workspaceSettings.service";

import { useHistory } from 'react-router-dom';


import {
  Container,
  Button,
  Row,
  Col,
  Spinner,
} from "reactstrap";

import errorResponses from "../ErrorHandling/errorResponses";
import { AddToolTip } from "../common/custom/AddToolTip";
import { Verbs } from "../../services/verbiage";
import Alert from "@material-ui/lab/Alert";
import CommonService from "../../services/common.service";
import StatusWithTimer from "./StatusWithTimer";
const MySwal = withReactContent(Swal)

const intervalDuration = 15000;
const getDeployedVSClassNames = (deployment_status) => {
  let deployedVSClassNames = "font-weight-bold";
  let nonDeployedVSClassNames = "font-weight-normal";
  return (deployment_status && (deployment_status === "Deployed"))
    ? deployedVSClassNames
    : nonDeployedVSClassNames
};
const columns = [
  { title: "Name", field: "stub_name", width: 170, render: rowData => <span className={getDeployedVSClassNames(rowData.deployment_status)}>{rowData.stub_name.replace('.json', '')}</span> },

  { 
    title: "Status", 
    field: "deployment_status", 
    width: 210, 
    render: rowData => (
      <StatusWithTimer 
        deploymentStatus={rowData.deployment_status} 
        updatedDatetime={rowData.updated_datetime} 
      />
    ) 
  },
  { title: "EndPoints", field: "endPoints_count", width: 60, render: rowData => <span className={getDeployedVSClassNames(rowData.deployment_status)}>{rowData.endPoints_count}</span> },
  {
    title: "Updated", field: "updated_datetime", type: "datetime", tooltip: "Updated date (updated by, if available)", defaultSort: "desc", render: rowData => {
      let date = new Date(rowData.updated_datetime);
      return <span className={getDeployedVSClassNames(rowData.deployment_status)}>{`${date.toLocaleDateString()} ${date.toLocaleTimeString()}${rowData.updated_by ? ' - ' + rowData.updated_by : ''}`}</span>
    }
  },
  {
    title: "Created", field: "created_datetime", type: "datetime", tooltip: "Created date (created by, if available)", render: rowData => {
      let date = new Date(rowData.created_datetime);
      return <span className={getDeployedVSClassNames(rowData.deployment_status)}>{`${date.toLocaleDateString()} ${date.toLocaleTimeString()}${rowData.created_by ? ' - ' + rowData.created_by : ''}`}</span>
    }
  },
];

export default function Simulations(props) {
  let history = useHistory();

  const [displayBanner, setDisplayBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [uploadNewStubOpen, setUploadNewStubOpen] = useState(false);
  const [vSPreview, setVSPreview] = useState(false);
  const [toggleDeployedAndLatestVersions, setToggleDeployedAndLatestVersions] = useState(true);
  const [clickedRow, setClickedRow] = useState("");
  const [sVHost, setSVHost] = useState("");
  const [selectedVS, setSelectedVS] = useState("");
  const [isScriptEngineEnabled, setIsScriptEngineEnabled] = useState(false);
  const [isDebugLogEnabled, setIsDebugLogEnabled] = useState(false);
  const [virtualServices, setVirtualServices] = useState([
    {
      stub_id: "",
      stub_name: "",
      endPoints_count: "",
      features: "",
      api: "",
      team: "",
      updated_datetime: "",
    },
  ]);
  const [loading, setLoading] = useState(true);
  const handleRowClick = async (rowData) => {
    try {
      setClickedRow(rowData);
      let response = await simulationService.getRawFileData({ s3_path: rowData.s3_path });
      let fileData = response.data.data;
      if (response.data.code === 200) {
        let formatedFileData = JSON.stringify(fileData, undefined, 4);
        setVSPreview(formatedFileData);
      } else {
        setVSPreview("File not found.");
      }
    }


    catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Could not fetch File Data. Corrupt File.',
        footer: errorResponses.GENERIC_FOOTER_ERROR
      })


    }
  };
  const handleEditVSClick = async (rowData) => {
    // On Edit we are reading the file data from s3 and then sending the epdata
    // to the Edit Simulation page.
    rowData["team_Application_id"] = props.location.state.team_Application_id;
    if (rowData.stub_name.includes(".jms")) {
      props.history.push({
        pathname: "managestub/editstubJms",
        state: {
          record: rowData,
          // epdata: responseData.epdata,
          application_code: props.location.state.application_code,
          team_code: props.location.state.team_code,
          program_name: props.location.state.program_name,
          valueChain_name: props.location.state.valueChain_name,
          valueChain_code: props.location.state.valueChain_code,
          team_id: props.location.state.team_id,
          valueChain_id: props.location.state.valueChain_id,
          application_id: props.location.state.application_id,
          team_Application_id: props.location.state.team_Application_id,
          sVHost: sVHost
        }
      });
    }
    else {
      props.history.push({
        pathname: "managestub/editstub",
        state: {
          record: rowData,
          // epdata: responseData.epdata,
          application_code: props.location.state.application_code,
          team_code: props.location.state.team_code,
          program_name: props.location.state.program_name,
          valueChain_name: props.location.state.valueChain_name,
          valueChain_code: props.location.state.valueChain_code,
          team_Application_id: props.location.state.team_Application_id,
          sVHost: sVHost
        }
      });
    }
  };
  const handleDeleteVSClick = async (rowData) => {
    swalBootstrapButtonsDangerousQuestion.fire({
      title: 'Are you sure?',
      text: 'You are about to delete the virtual service',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then(async (result) => {
        try {
          if (result.value) {
            let response = await simulationService.deleteVS({ id: rowData.stub_id });
            if (response && response.status === 200) {
              swalBootstrapButtonsQuestion.fire(
                'Deleted!',
                'Your Virtual Service has been deleted. If deployed, it will be undeployed in few minutes',
                'success'
              )
              updateData();
              setVSPreview("");
            }
          }
        }

        catch (error) {

          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Could not Delete the Virtual Service.',
            footer: errorResponses.GENERIC_FOOTER_ERROR
          })


        }
      });
  };

  const handleCloneVSClick = async (rowData) => {
    var name = "";
    var confirmation = false;
    var target;
    var dataToSendArr = [];

    Swal.fire({
      title: 'Enter filename',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        required: "required"

      },
      showCancelButton: true,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        name = value;
        confirmation = true;

      }
    }).then(() => {
      var dataToSend = rowData
      dataToSend['type'] = 'clone'
      dataToSend['name'] = name;
      dataToSendArr.push(dataToSend)
      target = {
        program: {
          program_id: props.location.state.program_id,
          program_code: props.location.state.program_code
        },
        valuechain: {
          valueChain_id: props.location.state.valueChain_id,
          valuechain_code: props.location.state.valueChain_code
        },
        application: {
          application_id: props.location.state.application_id,
          application_code: props.location.state.application_code
        },
        team: {
          team_id: props.location.state.team_id,
          team_code: props.location.state.team_code,
          team_application_id: props.location.state.team_Application_id,
          team_name: props.location.state.team_name
        },
        folder: props.location.state.folderStructureS3
      }
    }).then(() => {
      if (confirmation) {
        LibraryService.checkinVirtualServices(dataToSendArr, target)
          .then((response) => {
            if (response.data.success) {
              Swal.fire({
                title: "Congratulations !",
                html:
                  "Virtual Service Cloned",
                icon: "success",
                onClose: () => {
                  // This is intentional
                },
              }).then((result) => {
                  // This is intentional
              });
            }
            else {

              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Internal error occured',
                footer: 'Response from server' + JSON.stringify(response.data)
              });
            }

          }).then(
            () => updateData()
          ).catch((error) => {

            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Error while cloning the Virtual Service',
              footer: errorResponses.GENERIC_FOOTER_ERROR
            })


          })
      }

    }).catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error while cloning virtual service',
        footer: errorResponses.GENERIC_FOOTER_ERROR
      }
      );
    })




  };
  const handleCreateVSButtonClick = async () => {
    // Redirects to Create component with necessary props
    props.history.push({
      pathname: "createstub",
      state: {
        folderStructure_id: props.location.state.folderStructure_id,
        folderStructureS3: props.location.state.folderStructureS3,
        team_name: props.location.state.team_name,
        team_Application_id: props.location.state.team_Application_id,
        application_code: props.location.state.application_code,
        team_code: props.location.state.team_code,
        program_name: props.location.state.program_name,
        valueChain_name: props.location.state.valueChain_name,
        program_id: props.location.state.program_id,
        team_id: props.location.state.team_id,
        valueChain_id: props.location.state.valueChain_id,
        valueChain_code: props.location.state.valueChain_code,
        application_id: props.location.state.application_id,
        application_name: props.location.state.application_name,
      },
    });
  };

  const handleSettingsButtonClick = async () => {
    MySwal.fire({
      // icon: "info",
      width: 1200,
      allowOutsideClick: false,
      // title: 'How to access your virtual servies?',
      html: <SettingsComp sVHost={sVHost}></SettingsComp>,
      confirmButtonText: 'Close',
    }).then(() => {
      getScriptEngineAndDebugLogStatus()
    });
  };

  const handleUploadVS = (response) => {
    // For uploading the stub from a file
    if (response.data.code === 200) {
      Swal.fire("Success !", response.data.message, "success");
      updateData();
      setUploadNewStubOpen(false);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Changes could not be saved due to internal error',
        footer: errorResponses.GENERIC_FOOTER_ERROR
      }
      );

    }
  };
  // shouldDeployButtonBeDisabled? this function returns true if answer is yes, returns false if answer is no
  const shouldDeployButtonBeDisabled = () => {
    const cannotBeDeployed = (currentValue) => (
      currentValue.deployment_status === "Deployed" ||
      currentValue.deployment_status === "In progress - Deploying" ||
      currentValue.deployment_status === "Queued - Deploy"
    );
    // Are any virtual services selected?
    let result = !selectedVS.length;
    if (!result) {
      // If there are some virtual services selected, check if even some of them cannot be deployed
      result = selectedVS.some(cannotBeDeployed);
    }
    return result;
  };
  const handleDeployButtonClick = async () => {

    swalBootstrapButtonsQuestion.fire({
      title: 'Are you sure?',
      text: 'You are about to deploy the selected virtual services',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes, deploy',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then(async (result) => {
        if (result.value) {
          let deploymentParams = {
            action: "deploy",
            teamApplication: props.location.state.team_Application_id,
            virtualServices: selectedVS
          };
          setLoading(true);
          // used to deploy V Services.
          await simulationService.postVSDeployment(deploymentParams);
          // let response = await simulationService.checkIfDeploymentInProgress({ teamApplication: props.location.state.team_Application_id });
          // setDisplayBanner(response.data.inprogress && response.data.for_this_team_application);
          // setBannerMessage(response.data.message);
          updateData();

        }
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Unknown error occured when initiating the deployment',
          footer: errorResponses.GENERIC_FOOTER_ERROR
        }
        );

      });
  };

  const shouldDownloadButtonBeDisabled = () => {
    return !selectedVS.length;
  }
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const startDownload = (fileName) => {
    const apiUrl = CommonService.getApiUrl('stubs/download/' + fileName);
    window.location.href = apiUrl;
    setDownloadInProgress(false);
  }
  const handleDownloadButtonClick = () => {
    setDownloadInProgress(true);
    const services = selectedVS.map((service) => ({ s3_path: service.s3_path }));
    simulationService.getZipFileData({ services })
      .then((response) => {
        startDownload(response.data);
      })
      .catch((error) => {
        setDownloadInProgress(false);
        console.log(`Error occurred when attempting to download virtual services ${error}`);
      });
  }

  // shouldUndeployButtonBeDisabled? this function returns true if answer is yes, returns false if answer is no
  const shouldUndeployButtonBeDisabled = () => {
    const cannotBeUnDeployed = (currentValue) => (
      currentValue.deployment_status === "Ready For Deployment" ||
      currentValue.deployment_status === "In progress - Undeploying" ||
      currentValue.deployment_status === "Queued - Undeploy"
    );
    let result = !selectedVS.length;
    if (!result) {
      result = selectedVS.some(cannotBeUnDeployed);
    }
    return result;
  };
  const handleUndeployButtonClick = async () => {
    swalBootstrapButtonsDangerousQuestion.fire({
      title: 'Are you sure?',
      text: 'You are about to undeploy the selected virtual services',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, undeploy',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    })
      .then(async (result) => {
        if (result.value) {
          let deploymentParams = {
            action: "undeploy",
            teamApplication: props.location.state.team_Application_id,
            virtualServices: selectedVS
          };
          setLoading(true);
          await simulationService.postVSDeployment(deploymentParams);
          // let response = await simulationService.checkIfDeploymentInProgress({ teamApplication: props.location.state.team_Application_id });
          // setDisplayBanner(response.inprogress && response.for_this_team_application);
          // setBannerMessage(response.message);
          updateData();
        }
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire(
          "Error !",
          "Unknown error occured when initiating the undeployment",
          "error"
        );
      });
  };
  const handleShowDeployedVersionButtonClick = async () => {
    try {
      let response = await simulationService.getObjectByVersion({ keyValue: clickedRow.s3_path, versionId: clickedRow.deployed_version });
      let responseData = response.data.data;
      if (response.data.code === 200) {
        let formated = JSON.stringify(responseData, undefined, 4);
        setVSPreview(formated);
      } else {
        setVSPreview("File not found.");
      }

      setToggleDeployedAndLatestVersions(false);
    }
    catch (error) {

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error while getting the deployed version of the file',
        footer: errorResponses.GENERIC_FOOTER_ERROR
      }
      );
    }
  };
  const openDialogue = () => {
    MySwal.fire({
      icon: "info",
      width: 800,
      title: 'How to access your virtual servies?',
      html: <InfoDialog sVHost={sVHost}></InfoDialog>,
      confirmButtonText: 'Close',
    })
  }
  const handleShowLatestVersionButtonClick = async () => {
    try {
      let response = await simulationService.getRawFileData({ s3_path: clickedRow.s3_path });
      let fileData = response.data.data;
      if (response.data.code === 200) {
        let formatedFileData = JSON.stringify(fileData, undefined, 4);
        setVSPreview(formatedFileData);
      } else {
        setVSPreview("File not found.");
      }
      setToggleDeployedAndLatestVersions(true);
    }
    catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error while getting the latest version of the file',
        footer: errorResponses.GENERIC_FOOTER_ERROR
      }
      );

    }
  };

  const actions = [
    {
      icon: "edit",
      iconProps: {
        style: { color: palette.primary }
      },
      tooltip: "Edit Virtual Service",
      position: "row",
      onClick: (event, rowData) => handleEditVSClick(rowData),
    },
    {
      icon: "file_copy",
      iconProps: {
        style: { color: palette.info }
      },
      tooltip: "Clone Virtual Service",
      position: "row",
      onClick: (event, rowData) => handleCloneVSClick(rowData),
    },
    {
      icon: "delete",
      iconProps: {
        style: { color: palette.danger }
      },
      tooltip: "Delete Virtual Service",
      position: "row",
      onClick: (event, rowData) => handleDeleteVSClick(rowData),
    },
    {
      icon: "refresh",
      iconProps: { style: { fontSize: "30px", color: palette.primary } },
      tooltip: "Refresh statuses of virtual services",
      isFreeAction: true,
      onClick: (event) => updateDeploymentStatus(),
    }
  ];

  const updateDeploymentStatus = async () => {
    try {
      console.log("refreshing")
      // let response = await simulationService.checkIfDeploymentInProgress({ teamApplication: props.location.state.team_Application_id });
      // setDisplayBanner(response.data.inprogress && response.data.for_this_team_application);
      // setBannerMessage(response.data.message);

    }
    catch (error) {
      history.push({
        pathname: 'errorPage',
        state: {
          errorMessage: errorResponses.GENERIC_ERROR
        },
      });
    }
  };

  const updateData = async () => {
    setLoading(true);
    try {
      setSelectedVS("");
      let sVHostPromise = simulationService.getSVHost({ teamApplication: props.location.state.team_Application_id });
      let vSPromise = simulationService.getVirtualServices({ teamApplication: props.location.state.team_Application_id });
      // let checkIfDeploymentInProgressPromise = simulationService.checkIfDeploymentInProgress({ teamApplication: props.location.state.team_Application_id });
      let svHeaderName = simulationService.getSVHostHeader({ teamApplication: props.location.state.team_Application_id });
      // let responses = await Promise.all([sVHostPromise, vSPromise, checkIfDeploymentInProgressPromise, svHeaderName]);
      let responses = await Promise.all([sVHostPromise, vSPromise, svHeaderName]);

      setLoading(false);
      setSVHost(responses[0].data);
      setVirtualServices(responses[1].data);
      // setDisplayBanner(responses[2].data.inprogress && responses[2].data.for_this_team_application);
      // setBannerMessage(responses[2].data.message);
      // throw new Error("Hello error")
    }
    catch (error) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error while fetching data due to internal error',
        footer: errorResponses.GENERIC_FOOTER_ERROR
      }
      );

    }
  };

  const getScriptEngineAndDebugLogStatus = () => {
    setLoading(true);
    if (sVHost) {
      Promise.all([
        workspaceSettingsService.getGeneralSettings(sVHost),
        workspaceSettingsService.getDebugLoggingStatus(sVHost)
      ])
        .then((responses) => {
          setIsScriptEngineEnabled(responses[0].data.scriptEngine.isEnabled);
          setIsDebugLogEnabled(responses[1].data.isEnabled);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `Error while fetching script engine and debug log status for the workspace. ${error}`,
          });
        });
    }
  };

  useEffect(() => {
    getScriptEngineAndDebugLogStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sVHost]);

  // We have defined dependencies as displayBanner and bannerMessage which means,
  // when either of these values change, useEffect() is re-executed which re-loads the data on the page

  useEffect(() => {
    //TODO no need
    updateData();
    // const interval = setInterval(() => {
    //   updateDeploymentStatus();
    // }, intervalDuration);
    // // https://upmostly.com/tutorials/setinterval-in-react-components-using-hooks
    // return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayBanner, bannerMessage]);

  return (
    <Container>
      <div className="white mt-3">
        <UploadNewStub
          open={uploadNewStubOpen}
          setOpen={setUploadNewStubOpen}
          onConfirm={handleUploadVS}
          team_Application_id={props.location.state.team_Application_id}
          s3_path={props.location.state.folderStructureS3}
          team_name={props.location.state.team_name}
          application_code={props.location.state.application_code}
          team_code={props.location.state.team_code}
          program_name={props.location.state.program_name}
          valueChain_name={props.location.state.valueChain_name}
          valueChain_code={props.location.state.valueChain_code}
        ></UploadNewStub>
        <Row>
          <Col><h2>Manage Virtual Services</h2>
            {displayBanner ? (<p className="ml-1 mt-6 text-success">{bannerMessage}</p>) : null}</Col>
          <Col xs="auto">
            <Row>
              <Col className="text-right pr-0" xs="auto">
                <Button color="info" className="mr-2" onClick={() => setUploadNewStubOpen(true)}>Upload VS <Publish></Publish></Button>
                <Button color="info" className="mr-2" onClick={() => handleCreateVSButtonClick()}>Create VS <AddBox></AddBox></Button>
              </Col>
              <Col className="text-right pl-0" xs="auto">
                <AddToolTip placement="top-start" title={Verbs.manageVS.settingsButton}>
                  <Button hidden={!sVHost} color="secondary" onClick={() => handleSettingsButtonClick()}>Settings <Settings></Settings></Button>
                </AddToolTip>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className='mt-3'>
            <Typography variant="subtitle1">
              Team Name : {props.location.state.team_name}
            </Typography>
            <Typography variant="subtitle1">
              Application Name : {props.location.state.application_name}
            </Typography>
          </Col>
        </Row>
        {(isScriptEngineEnabled || isDebugLogEnabled) ?
          <Alert className="mt-2">
            {'Following features are currently enabled, to disable visit respective section in "Settings"'}
            <ol>
              {isScriptEngineEnabled && <li> <strong>-</strong> Script engine</li>}
              {isDebugLogEnabled && <li> <strong>-</strong> Debug logging</li>}
            </ol></Alert> : null}
        {loading ? (
          <Row className="mt-3">
            <Col className="text-center">
              <Spinner className="p-5" color="primary" />
            </Col>
          </Row>
        ) :
          (
            <Grid item md={12} xs={12} className="mt-3">
              <MaterialTable
                columns={columns}
                title={<p style={{ display: "inline-block", cursor: "pointer", color: 'blue' }}
                  className="text-primary mb-0"
                  onClick={openDialogue}
                  hidden={!sVHost}><Info fontSize="small"  ></Info><span className="align-middle ml-1">How to access your virtual services?</span>
                </p>}
                data={virtualServices}
                actions={actions}
                onRowClick={(event, selectedRow) => {
                  handleRowClick(selectedRow);
                }}
                onSelectionChange={(selectedRows) =>
                  setSelectedVS(selectedRows)
                }
                options={{
                  selection: true,
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      clickedRow &&
                        clickedRow.tableData.id === rowData.tableData.id
                        ? palette.blueseq[0]
                        : "#FFF",
                  }),
                  headerStyle: {
                    fontWeight: "bold",
                    backgroundColor: palette.blueseq[3],
                  },
                  paging: virtualServices.length > 5,
                  actionsColumnIndex: -1,
                }}
              />

              <Row>
                <Col xs={1}>
                </Col>
                <Col className="text-right">
				 <Button
                    className="mt-4 mr-2"
                    onClick={() => handleDownloadButtonClick()}
                    disabled={shouldDownloadButtonBeDisabled()}
                    color="info"
                  >
                    Download
                    <CircularProgress size="1.1rem" className="ml-2" hidden={!downloadInProgress} />
                  </Button>
                  <Button
                    className="mt-4 mr-2"
                    onClick={() => handleUndeployButtonClick()}
                    // display banner 1 means deployment is in progress,
                    disabled={shouldUndeployButtonBeDisabled()}
                    // disabled = {!showDeployButton}
                    color="warning"
                  >
                    Undeploy Selected
                  </Button>
                  <Button
                    className="mt-4"
                    onClick={() => handleDeployButtonClick()}
                    disabled={shouldDeployButtonBeDisabled()}
                    color="primary"
                  >
                    Deploy Selected
                  </Button>
                </Col>
              </Row>
              {(vSPreview) ? (
                <>
                  {(clickedRow.deployed_version && clickedRow.deployment_status.includes("Deployed(Modified)") && toggleDeployedAndLatestVersions) ?
                    (
                      <Row><Col xs={9}></Col>
                        <Col className="text-right">
                          <Button
                            style={{ marginTop: "2vh" }}
                            onClick={handleShowDeployedVersionButtonClick}
                            color="info"
                          >
                            Show Deployed Version
                          </Button>
                        </Col></Row>) : null}
                  {(clickedRow.deployed_version && clickedRow.deployment_status.includes("Deployed(Modified)") && !toggleDeployedAndLatestVersions) ?
                    (
                      <Row><Col xs={9}></Col>
                        <Col className="text-right">
                          <Button
                            style={{ marginTop: "2vh" }}
                            onClick={handleShowLatestVersionButtonClick}
                            color="info"
                          >
                            Show Latest Version
                          </Button>
                        </Col></Row>) : null}
                  <Row>
                    <Col xs="12">
                      {/* here it will be current or final one  */}
                      <br></br>{" "}
                      <Preview
                        preview={vSPreview}
                        buttonName={"finish"}
                        rows={25}
                      ></Preview>
                    </Col>
                  </Row>
                </>) : null}
            </Grid>
          )}
      </div>
    </Container>
  );
}
