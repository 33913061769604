// Tool tip text are kept in this file

import { confluenceURLs } from '../constants';

export const Verbs = {
	title: {
		s3VersionSelection: 'Select version. You can save a selected version by clicking Save virtual service button after version selection.',
		label: 'A custom label to identify the endpoint easily.',
		endpointId: 'A unique ID auto-assigned to the endpoint. It can be used to identify the endpoint in usecases such as CJV.',
		requestType: 'Choose from the available request types.',
		method: 'Method matcher for the HTTP request',
		path: 'The path matcher as per the standard URI scheme. This does not include the query parameters that are provided after an ampersand (&)',
		query: 'Optional, query value matchers. To be used only if it is necessary to match incoming request\'s query params.',
		requestHeader: 'Optional, header value matchers. To be used only if it is necessary to match incoming request\'s headers.',
		requestBody: 'Optional, body value matcher. To be used only if it is necessary to match incoming request\'s body.',
		status: 'HTTP response status code, eg. 200 - success , 501-internal error, 301-Moved Permanently, 403- Forbidden. Allowed values are numbers between 100 and 599',
		responseHeader: 'HTTP response headers eg. Accept: text/html,Content-Type: application/JSON.',
		responseBody: 'HTTP response body',
		responseTemplate: `Enabling templated response lets you create a dynamic response. Usage: ${confluenceURLs.createVSCookbook.displayText}`,
		enableResponseDelay: 'Enable/disable latency configuration in the response. The latency (in milliseconds) to be applied to the response. Maximum accepted delay: 3 seconds.',
		responseDelay: 'Latency value that will be simulated (in milliseconds)',
		btnFEPCancel: 'Discard changes and go to endpoints table',
		btnFEPDelete: 'Delete endpoint',
		btnFEPReset: 'Discard changes and reset form to its default',
		btnFEPNext: 'Record the changes and go to endpoints table',
		btnVSCancel: 'Discard changes made to all endpoints and go back to mange virtual services screen',
		btnVSPreview: 'Preview virtual service json',
		btnVSAddEndpoint: 'Add endpoint',
		btnSaveVS: 'Save all the changes made to the virtual service endpoint pairs',
		description: ' This will assist users in comprehending the value of the stub.',
		request: {
			HTTP: {

			},
			JMS: {
				queueTopicName: 'Matcher for queue or topic name',
				message: 'Optional, message matcher',
				jsmTypeMessage: 'Choose queue/topic'
			},
			Tibco: {
				queueTopicName: 'Matcher for queue or topic name',
				message: 'Optional, message matcher',
				tibcoTypeMessage: 'Choose queue/topic'
			},
			CometD: {
				channel: 'Matcher for CometD channel',
				message: 'Optional, message matcher'
			},
			Kinesis: {
				streamName: 'Matcher for kinesis stream name',
				message: 'Optional, message matcher'
			},
			IBMMQ: {
				queueName: 'Matcher for IBMMQ queue name',
				message: 'Optional, message matcher'
			},
			SQS: {
				queueTopicName: 'Matcher for queue name',
				message: 'Optional, message matcher',
			}
		},
		CJV: {
			toggle: 'Toggle to switch between real and virtual endpoints'
		},
		response: {
			HTTP: {
				actions: 'Additional actions that need to be performed before sending the response'
			},
			JMS: {
				actions: 'Actions that need to be performed when matching request is received'
			},
			Tibco: {
				actions: 'Actions that need to be performed when matching request is received'
			},
			CometD: {
				actions: 'Actions that need to be performed when matching request is received'
			},
			Kinesis: {
				actions: 'Actions that need to be performed when matching request is received'
			},
			IBMMQ: {
				actions: 'Actions that need to be performed when matching request is received'
			},
			SQS: {
				action: 'Actions that need to be performed when matching request is received'
			}
		},
		responseActions: {
			responseAction: {
				actionType: 'The type of action to perform',
				actionLabel: 'A custom label to identify the action easily.',

				template: 'Optional, enable/disable templating in this action. With templating you have access to request components and previous actions\' responses',
				condition: 'Optional, provide a condition scriptlet to detemine whether this action should be performed. For more information, refer to user guide.',
				async: 'Optional, enable/disable to perform action in an asynchronous manner',
				delay: 'Optional, enable/disable asynchronous delay to execute actions after a defined duration',
				delayValue: 'In seconds. Action will be executed after the defined duration elapses.',
				forEach: 'Optional, Provide the forEach scriptlet. For more information, refer to user guide.',
				validationMessages: {
					conditionInvalid: 'Condition scriptlet is invalid',
					forEachInvalid: 'Loop scriptlet is invalid',
				},
			},
			protocolProxyBaseURL: 'The protocol proxy url that the SV engine will connect to perform the action',
			httpForward: {
				method: 'HTTP method of the request. GET, POST and so on.',
				mASSL: 'Enable or disable MASSL',
				mASSLCertAlias: 'Provide the MASSL alias that is given to the certificate in workspace settings',
				masslPassword: 'Provide MASSL passphrase (if any)',
				url: 'URL (exclusing query parameters) of the request',
				queryParams: 'Query parameters to add to the request',
				headers: 'Headers to add to the request',
				body: 'Request body',
				validationMessages: {
					urlInvalid: 'Not a valid URL. Ensure query parameters are not included and length of the URL is not more 2000 characters.',
					queryListLengthInvalid: 'Query Limit exceeded. You will be unable to add more query strings.',
					headerListLengthInvalid: 'Header Limit exceeded. You will be unable to add more headers.',
					bodyInvalid: 'Ensure body length is not more than 50,000 characters',
				}
			},
			// TODO HIGH Update the corresponding text and confgure in the components
			sqsQueue: {
				name: 'Required. The name of the queue ',
				authorizationToken: 'Optional. The authorization token defined when starting the sv-protocol-proxy service.',
				contentType: 'The content type of the message such as application/json, text/plain'
			},
			jmsWrite: {
				name: 'Required. The name of the queue or topic',
				authorizationToken: 'Optional. The authorization token defined when starting the sv-protocol-proxy service.',
				contentType: 'The content type of the message such as application/json, text/plain'
			},
			tibcoWrite: {
				name: 'Required. The name of the queue or topic',
				authorizationToken: 'Optional. The authorization token defined when starting the sv-protocol-proxy service.',
				contentType: 'The content type of the message such as application/json, text/plain'
			},
			kinesisWrite: {
				authorizationToken: 'Optional. The authorization token defined when starting the sv-protocol-proxy service.',
				contentType: 'The content type of the message such as application/json, text/plain'
			},
			ibmmqWriteQueue: {
				authorizationToken: 'Optional. The authorization token defined when starting the sv-protocol-proxy service.',
				contentType: 'The content type of the message such as application/json, text/plain'
			},
			database: {
				activityType: 'Type of activity is detemined by whether you want to retrieve data from database or make changes to rows',
				query: 'The database query you want to execute',
				authorizationToken: 'Optional. The authorization token defined when starting the sv-protocol-proxy service.',
				contentType: 'The content type. For database action, content-type will be text/plain'
			},
			executeJS: {
				script: 'The javascript code to execute.'
			},
			properties: {
				body: 'The text provided will be parsed by SV and any variables created using templating will be available in subsequent actions and original response body'
			},
			smtp: {
				from: 'From Address (example@team.telstra.com)',
				to: 'To Address (example@team.telstra.com)',
				body: 'E-MAIL body',
				attachment: 'Comma seperated file name(s) which are uploaded for the workspace',
				subject: 'Subject for the E-MAIL'
			},
		}
	},
	manageVS: {
		settingsButton: 'Manage workspace settings such as MASSL certificates'
	},
	apiTokens: {
		tokenDescription: 'Provide a description for the API token. This can be used to identify the token purpose etc.,'
	},
	validationMessages: {
		pathInvalid: 'Path is invalid. Please ensure path starts with a \'/\' and is under 2000 characters.',
		queryListLengthInvalid: 'Query Limit exceeded. You will be unable to add more query strings.',
		headerListLengthInvalid: 'Header Limit exceeded. You will be unable to add more headers.',
		responseStatusInvalid: 'Response status code should be > 100 and < 599',
		responseHeaderListLengthInvalid: 'Header Limit exceeded. You will be unable to add more headers.',
		responseActionListLengthInvalid: 'Action Limit exceeded. You will be unable to add more actions.',
		protocolProxyEmpty: 'Protocol Proxy URL can\'t be empty',
		methodTemplateInvalid: "Invalid method. Please ensure that the method is a valid HTTP method or a template that starts with '{{' and ends with '}}'.",
		invalidDelay: "Delay must start with '{{' and end with '}}'.",
		fromAddressInvalid: "Invalid From Address",
		toAddressInvalid: "Invalid To Address"
	}
};