import React, { useEffect, useState } from 'react';

const StatusWithTimer = ({ deploymentStatus, updatedDatetime }) => {
  const isStatusRelevant = ["Deployed", "Undeployed"].includes(deploymentStatus);
  const updatedTime = new Date(updatedDatetime);
  const timeDiff = (Date.now() - updatedTime.getTime()) / 1000; // in seconds
  const initialCounter = Math.max(60 - Math.floor(timeDiff), 0);
  const [counter, setCounter] = useState(initialCounter);
  
  const getDeployedVSClassNames = (deployment_status) => {
    let deployedVSClassNames = "font-weight-bold";
    let nonDeployedVSClassNames = "font-weight-normal";
    return (deployment_status && (deployment_status === "Deployed"))
      ? deployedVSClassNames
      : nonDeployedVSClassNames
  };

  useEffect(() => {
    let timer;
    if (isStatusRelevant && timeDiff < 60) {
      timer = setInterval(() => {
        setCounter(prev => {
          if (prev > 0) {
            return prev - 1;
          }
          clearInterval(timer);
          return 0;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [timeDiff, isStatusRelevant]);

  let displayText;
  if (isStatusRelevant && timeDiff < 60) {
    if (deploymentStatus.toLowerCase() === 'deployed') {
      displayText = `Deploying (${counter}s)`;
    } else if (deploymentStatus.toLowerCase() === 'undeployed') {
      displayText = `Undeploying (${counter}s)`;
    }
  } else {
    displayText = deploymentStatus;
  }
  return <span className={getDeployedVSClassNames(deploymentStatus)}>{displayText}</span>;
};

export default StatusWithTimer;
