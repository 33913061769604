/* eslint-disable default-case */
import React, { useState, useEffect, useRef, useContext } from 'react';
import MaterialTable from 'material-table';
import Switch from 'react-switch';
import Swal from 'sweetalert2';
import { FaExclamationCircle } from 'react-icons/lib/fa';
import _ from 'lodash';
import ResponseAction from './ResponseActions/ResponseAction';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalState';

import {
	swalBootstrapButtonsQuestion,
	swalBootstrapButtonsDangerousQuestion,
} from '../utils/swal';

import FilePreview from './FilePreview';
import { AddToolTip } from '../common/custom/AddToolTip';

import { palette } from '../../assets/colors';

import simulationService from '../../services/simulation.service';
import CJVScenarioService from '../../services/cJVScenarios.service';
import errorResponses from '../ErrorHandling/errorResponses';

import {
	hasExceededQueryRows,
	isPathInvalid,
	hasExceededRequestHeaderRows,
	hasExceededResponseHeaderRows,
	hasExceededResponseActionRows,
	MAX_BODY_SIZE,
	isJMSPathInvalid,
	isTibcoPathInvalid,
	isCometDPathInvalid,
	isResponseStatusInvalid,
	MAX_ACTION_DELAY_SECONDS,
	MAX_BODY_RESPONSE_SIZE,
	isMethodTemplateValid
} from '../utils/stubValidators';

import {
	http_methods,
	matchers,
	http_body_matchers,
	request_types,
	default_response_action,
	default_vs,
} from './constants';

import {
	processVirtualService,
	collateVirtualService,
} from './utils';

import {
	Container,
	Button,
	Row,
	Col,
	Input,
	Card,
	CustomInput,
	Spinner,
	Jumbotron,
	Form,
	FormFeedback,
} from 'reactstrap';
import {
	Divider,
	InputLabel,
	Collapse,
	Typography,
	IconButton,
	Tooltip,
} from '@material-ui/core';

import {
	AddCircle,
	RemoveCircle,
	KeyboardArrowRight,
	KeyboardArrowDown,
} from '@material-ui/icons';
import { Verbs } from '../../services/verbiage';
import JMS from './RequestTypeForms/JMS';
import Tibco from './RequestTypeForms/Tibco';
import CometD from './RequestTypeForms/CometD';
import Kinesis from './RequestTypeForms/Kinesis';
import IBMMQ from './RequestTypeForms/IBMMQ';
import CJVScenarios from './CJV/CJVScenarios';
import SQS from './RequestTypeForms/SQS';

import { getRole, getUserDetails } from '../../services/authentication';
import workspaceSettingsService from '../../services/workspaceSettings.service';
var parser = require('fast-xml-parser');

var Validator = require('jsonschema').Validator;
const scrollToRef = (ref) => {
	window.scrollTo({
		behavior: 'smooth',
		top: ref.current.offsetTop,
	});
};


/**
 * Synonyms:
 *  - VS <-> VirtualService
 *
 * Description:
 * It renders a virtual service.
 *
 * Logic:
 * If -> props.location.state.stub_id is defined,
 *  if -> props.location.state.write is true edit virtual service page is rendered (exactly same as view but will be open for editing)
 *  else -> view virtual service page is rendered (read-only version of the virtual service)
 * else -> new virtual service page is rendered
 *
 *
 * @param {*} props
 * TODO *
 * 2. We should not allow ?, query should be given in query
 */
export default function VirtualService(props) {
	// Only used when saving a new service
	const userDetails = getUserDetails();
	const team_application_id = props.location.state.team_Application_id;
	// stub_id undefined translates to *component is opened to create VS*
	let stub_id = undefined;
	let s3_path = undefined;
	let virtualServiceName = '';
	const {
		setCjvElements,
		user_role
	} = useContext(GlobalContext);
	if ('record' in props.location.state) {
		// Because, we have the record in props, it is either view or edit
		stub_id = props.location.state.record.stub_id;
		s3_path = props.location.state.record.s3_path;
		virtualServiceName = props.location.state.record.stub_name;
	}

	// Determine if stub is editable based on pathname
	const isEditable =
		props.location.pathname.includes('edit') ||
		props.location.pathname.includes('create');
	const renderTitle = () => {
		let title = '';
		if (stub_id && isEditable) {
			title = 'Edit virtual service';
		} else if (stub_id && !isEditable) {
			title = 'View virtual service';
		} else if (!stub_id) {
			title = 'Create virtual service';
		}
		return <h3>{title}</h3>;
	};
	// This variable is to fix a react bug of not re-rendering on a state update
	const [revision, setRevision] = useState(false);
	// This sets the loading state on the page whenever any data is being fetched
	const [loading, setLoading] = useState(false);
	const [cjvMaterialLoader, setCJVMaterialLoader] = useState(false);
	const [sVHostHeader, setSVHostHeader] = useState('');
	const default_vs_clone = _.cloneDeep(default_vs);

	const [showCjvDiagramLoader, setShowCjvDiagramLoader] = useState(false);
	const [endpointPairs, setEndpointPairs] = useState(
		default_vs_clone.data.pairs
	);
	const [isOpen, setIsOpen] = useState(false);
	const [isCJVFlowOpen, setIsCJVFlowOpen] = useState(false);
	const [isCJVGenerated, setIsCJVGenerated] = useState(false);

	const [endPointIndexAndId, setEndPointIndexAndId] = useState({});

	const [vSRevision, setVSRevision] = useState(false);
	const [cjvFilename, setCjvFilename] = useState('');
	const [cjvFocussedEndpoint, setCjvFocussedEndpoint] = useState('');
	const [activateCJV, setActivateCJV] = useState(false);
	const [defaultScenario, setDefaultScenario] = useState('');
	const defaultCjvFileListS3 = [{ 'cjv_file_name': 'testFile.json', 'Filename': 'hello.json' }];
	const [cjvFilesListS3, setCJVFilesListS3] = useState(defaultCjvFileListS3);
	const [isVirtualServiceSubmitted, setIsVirtualServiceSubmitted] = useState(false);
	const [checkPathExistFirstTime, setCheckPathExistFirstTime] = useState(false);

	const CJVColumns = [{
		title: 'Name',
		field: 'Filename',
		render: rowData => {
			return rowData.Filename;
		}
	},
	{
		title: 'CJV scenario type',
		field: 'Filename',

		render: rowData => {
			if (rowData.Filename === defaultScenario) {
				return <b>Default CJV scenario</b>;
			}
			else if (rowData.Filename) {
				return 'CJV scenario';
			}
			else {
				return '';
			}
		}
	},
	{
		title: 'Last Updated On',
		field: 'LastModified'
	}

	];

	const cjvActions = [

		{
			icon: () => <Button color="primary">Generate CJV</Button>,

			iconProps: { style: { fontSize: '35px', color: palette.primaryshade2 } },
			tooltip: 'Add endpoint',
			isFreeAction: true,
			hidden: !isEditable,
			onClick: () => {
				handleGenerateCJV();
			},
		},
		{
			icon: 'delete',
			iconProps: {
				style: { color: palette.danger },
			},
			tooltip: 'Delete CJV',
			position: 'row',
			hidden: !isEditable || cjvFilesListS3.length === 1,
			onClick: (event, rowData) => handleDeleteCJVClick(rowData),
		}
	];
	const handleCancelClick = () => {
		swalBootstrapButtonsDangerousQuestion
			.fire({
				title: 'Are you sure?',
				text: 'Any changes made will be discarded',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				reverseButtons: true,
			})
			.then(async (result) => {
				if (result.value) {
					props.history.goBack();
				}
			});
	};
	const [filePreviewToggle, setFilePreviewToggle] = useState(false);
	const [showCjvLoader, setShowCjvLoader] = useState(false);
	const handlePreviewClick = () => {
		setFilePreviewToggle(!filePreviewToggle);
	};
	const collapseEverything = () => {
		setIsOpen(false);
		setIsCJVFlowOpen(false);
		setActivateCJV(false);

	};
	const goToManagePage = () => {
		props.history.push({
			pathname: (getRole() !== 'support') ? '/managestub' : '/support/managestub',
			state: {
				application_code: props.location.state.application_code,
				application_id: props.location.state.application_id,
				folderStructureS3: props.location.state.folderStructureS3,
				program_name: props.location.state.program_name,
				team_Application_id: props.location.state.team_Application_id,
				team_code: props.location.state.team_code,
				team_id: props.location.state.team_id,
				team_name: props.location.state.team_name,
				valueChain_id: props.location.state.valueChain_id,
				valueChain_name: props.location.state.valueChain_name,
				valueChain_code: props.location.state.valueChain_code,
			},
		});
	};



	const handelCJVEndPoint = (event, rowData) => {
		setShowCjvDiagramLoader(true);
		setIsCJVFlowOpen(false);

		setCjvFocussedEndpoint(rowData);
		setIsCJVGenerated(false);
		setShowCjvLoader(true);
		CJVScenarioService.readCJVFileS3({ s3_file_path: rowData.Key }).then(
			(res) => {
				setCjvElements(res.data.data);

				setRevision(!revision);
				setShowCjvLoader(false);
				let cjvFilesListS3Clone = _.cloneDeep(cjvFilesListS3);
				let a = cjvFilesListS3Clone.filter((cjvFileListItem) => {
					return cjvFileListItem.Key === rowData.Key;
				})[0];
				setCjvFilename(a.Filename);
				setIsCJVFlowOpen(true);

				setShowCjvDiagramLoader(false);
			}
		);
	};

	const handleSaveVSAfterCJV = (resEndpointPairs) => {
		try {
			// fileName: virtualServiceName.replace(".json", ""),

			return new Promise((resolve) => {
				if (stub_id && s3_path) {
					// This is virtual service update pathway
					let newRecord = props.location.state.record;
					newRecord.endPoints_count = resEndpointPairs.length;
					newRecord.stub_name = virtualServiceName;

					let promise1 = simulationService.changeDeploymentStatus({
						stub_id: stub_id,
					});
					try {
						let input_value = {
							record: newRecord,
							updatedData: collateVirtualService(resEndpointPairs, sVHostHeader),
						};
						let promise2 = simulationService.saveSimulation(input_value);
						Promise.all([promise1, promise2])
							.then(([response1, response2]) => {
								if (!(response2.data.code === 200)) {
									throw new Error(response2.data.message);
								}
								if (!response1) {
									throw new Error(
										'Error while updating deployment_status column. Please retry.'
									);
								}
								resolve({
									data: response2.data,
									status: response2.status,
									statusText: response2.statusText,
								});
							})
							.catch((error) => {
								Swal.showValidationMessage(`Unable to save: ${JSON.stringify(error)}`);
								resolve(false);
							});
					} catch (error) {
						// TODO error
						console.log(error);
					}

				}
			});
		} catch (error) {
			// TODO handle this error
			console.log(error);
		}
	};
	const handleSaveClick = () => {
		swalBootstrapButtonsQuestion
			.fire({
				title: 'Enter virtual service name',
				input: 'text',
				inputAttributes: {
					autocapitalize: 'off',
				},
				inputValue: virtualServiceName.replace('.json', ''),
				inputValidator: (value) => {
					if (!value) {
						// https://sweetalert2.github.io/#input-types
						return 'Please provide a name for your virtual service';
					}
				},
				showCancelButton: true,
				confirmButtonText: 'Done',
				cancelButtonText: 'Cancel',
				reverseButtons: true,
				showLoaderOnConfirm: true,
				onOpen: function () {
					if (stub_id && s3_path) {
						Swal.disableInput();
					}
				},
				preConfirm: (value) => {
					return new Promise((resolve) => {
						if (stub_id && s3_path) {
							// This is virtual service update pathway
							let newRecord = props.location.state.record;
							newRecord.endPoints_count = endpointPairs.length;
							newRecord.stub_name = value + '.json';

							let promise1 = simulationService.changeDeploymentStatus({
								stub_id: stub_id,
							});
							try {
								let input_value = {
									record: newRecord,
									updatedData: collateVirtualService(endpointPairs, sVHostHeader),
								};
								let promise2 = simulationService.saveSimulation(input_value);
								Promise.all([promise1, promise2])
									.then(([response1, response2]) => {
										if (!(response2.data.code === 200)) {
											throw new Error(response2.data.message);
										}
										if (!response1) {
											throw new Error(
												'Error while updating deployment_status column. Please retry.'
											);
										}
										resolve({
											data: response2.data,
											status: response2.status,
											statusText: response2.statusText,
										});
									})
									.catch((error) => {
										Swal.showValidationMessage(`Unable to save: ${JSON.stringify(error)}`);
										resolve(false);
									});
							} catch (error) {
								// TODO error
								console.log(error);
							}

						} else {
							// This is virtual service create pathway
							try {
								let input_value = {
									team: props.location.state.team_name,
									fileName: value,
									configData: collateVirtualService(endpointPairs, sVHostHeader),
									// folderStructure_id: folderStructure_id,
									s3_path: props.location.state.folderStructureS3,
									team_Application_id: props.location.state.team_Application_id,
								};
								simulationService
									.createSimulation(input_value)
									.then((response) => {
										if (!(response.data.code === 200)) {
											throw new Error(response.data.message);
										}
										resolve({
											data: response.data,
											status: response.status,
											statusText: response.statusText,
										});
									})
									.catch((error) => {
										Swal.showValidationMessage(`Unable to save: ${error}`);
										resolve(false);
									});
							} catch (error) {
								// TODO handle this error
								console.log(error);
							}

						}
					});
				},
				allowOutsideClick: () => !Swal.isLoading(),
			})
			.then((result) => {
				if (
					result.value &&
					result.value.data &&
					result.value.data.code === 200
				) {
					Swal.fire({
						title: 'Congratulations !',
						html:
							'Virtual service has been saved. \n Now you can deploy the service from the \'Manage Virtual Services\' page',
						icon: 'success',
					}).then(() => {
						if (stub_id && s3_path) {
							props.history.goBack();
						} else {
							props.history.push({
								pathname: 'managestub',
								state: {
									application_code: props.location.state.application_code,
									application_id: props.location.state.application_id,
									application_name: props.location.state.application_name,
									folderStructureS3: props.location.state.folderStructureS3,
									program_name: props.location.state.program_name,
									team_Application_id: props.location.state.team_Application_id,
									team_code: props.location.state.team_code,
									team_id: props.location.state.team_id,
									team_name: props.location.state.team_name,
									valueChain_id: props.location.state.valueChain_id,
									valueChain_name: props.location.state.valueChain_name,
									valueChain_code: props.location.state.valueChain_code,
								},
							});
						}
					});
				}
			});
	};

	const [selectedVersion, setSelectedVersion] = useState('1(latest)');

	const [s3VersionDetails, setS3VersionDetails] = useState({
		result: [
			{
				LastModified: '',
				VersionId: '',
				version: '1(latest)',
				IsLatest: true,
				Key: '',
			},
		],
	});
	const saveEndpointOnNext = () => {
		setIsAnEndpointFocussed(false);
		setEndpointPairs(
			endpointPairs.map((pair) => {
				if (pair.tableData.id === focussedEndpoint.tableData.id) {
					let newPair = _.cloneDeep(focussedEndpoint);
					newPair.label = fEPLabel;
					newPair.description = fEPDescription;
					newPair.request.requestType = fEPRequestType;
					newPair.request.method = fEPRequestMethod;
					newPair.request.path = fEPRequestPath;

					newPair.request.query = {};
					fEPRequestQueryParamsList.forEach((item) => {
						if (newPair.request.query[item.querykey]) {
							// If the querykey exists, push the new object into the array
							newPair.request.query[item.querykey].push({
								value: item.value,
								matcher: item.matcher,
							});
						} else {
							// If the querykey doesn't exist, create a new array with the object
							newPair.request.query[item.querykey] = [
								{ value: item.value, matcher: item.matcher },
							];
						}
					});
					newPair.request.headers = {};
					fEPRequestHeadersList.forEach((item) => {
						newPair.request.headers[item.headerkey] = [
							{ value: item.value, matcher: item.matcher },
						];
					});
					newPair.request.body = fEPRequestBody;
					newPair.response.status = fEPResponseStatus;
					newPair.response.areResponseActionsEnabled = areFEPResponseActionsEnabled;
					newPair.response.headers = {};
					fEPResponseHeadersList.forEach((item) => {
						newPair.response.headers[item.headerkey] = [item.value];
					});
					if (isFEPResponseDelayEnabled) {
						console.log("setting up fEPResponseDelay " + fEPResponseDelay)
						newPair.response.fixedDelay = fEPResponseDelay;
					}
					newPair.response.templated = isFEPResponseTemplated;
					newPair.response.body = fEPResponseBody;
					if (areFEPResponseActionsEnabled) {
						newPair.response.actions = fEPResponseActions;

					}
					return newPair;
				} else {
					return pair;
				}
			})
		);


		scrollToRef(endpointsTableRef);
	};
	const runValidate = () => {

		let executeFollowingCode = true;
		let validationErrorMessage = '';

		let v = new Validator();
		let instance = 4;
		//  validating response json format
		try {
			let bodyType = checkTypeOfString(fEPResponseBody);

			let replacementBody = replaceForSyntaxValidations(fEPResponseBody);

			if (bodyType === 'json') {
				try {
					v.validate(instance, JSON.parse(replacementBody));
				}
				catch (error) {
					// it means syntax is not correct, and saving won't happen
					executeFollowingCode = false;
					validationErrorMessage = 'json syntax error in response body';

				}
			}
			else if (bodyType === 'xml') {
				if (parser.validate(replacementBody) !== true) {
					executeFollowingCode = false;
					validationErrorMessage = 'json syntax error in response body';
				}

			}

		}
		catch (error) {
		}
		try {

			if (areFEPResponseActionsEnabled) {


				let i = 0;
				let fEPResponseActionsValidations = _.cloneDeep(fEPResponseActions);

				for (let actionItem of fEPResponseActionsValidations) {
					let bodyType = '';
					let replacementBody = '';
					let bodyOrMessage = 'body';
					if (actionItem.actionType === 'httpForward') {
						// gets the type of the body, json or xml or other
						bodyType = checkTypeOfString(actionItem.actionData.body);
						replacementBody = replaceForSyntaxValidations(actionItem.actionData.body);
					}
					else if (actionItem.actionType === 'jmsWriteTopic' || actionItem.actionType === 'jmsWriteQueue' ||
						actionItem.actionType === 'tibcoWriteTopic' || actionItem.actionType === 'tibcoWriteQueue') {
						bodyOrMessage = 'message';
						bodyType = checkTypeOfString(actionItem.actionData.qOrTMessage);
						replacementBody = replaceForSyntaxValidations(actionItem.actionData.qOrTMessage);
					}
					else if (actionItem.actionType === 'kinesisWrite') {
						bodyOrMessage = 'message';
						bodyType = checkTypeOfString(actionItem.actionData.streamMessage);
						replacementBody = replaceForSyntaxValidations(actionItem.actionData.streamMessage);
					}
					if (bodyType === 'json') {
						try {
							v.validate(instance, JSON.parse(replacementBody));
						}
						catch (error) {
							// it means syntax is not correct, and saving won't happen
							executeFollowingCode = false;
							validationErrorMessage = 'json syntax error in action' + i + '\'s ' + bodyOrMessage;

						}

					}
					else if (bodyType === 'xml') {
						if (parser.validate(replacementBody) !== true) {
							executeFollowingCode = false;
							validationErrorMessage = 'xml syntax error in action' + i + '\'s ' + bodyOrMessage;
						}

					}

					i = i + 1;
				}
			}
		}
		catch (error) {
		}

		if (!executeFollowingCode) {
			//something went wrong in sytax validation

			Swal.fire({
				title: validationErrorMessage + ', do you want to proceed?',
				// text: 'You are about to delete the virtual service',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				reverseButtons: true
			})
				.then(async (result) => {
					try {
						if (result.value) {
							saveEndpointOnNext();
						}
					}

					catch (error) {

						Swal.fire({
							icon: 'error',
							title: 'Error',
							text: 'Error while saving endpoint',
						});


					}
				});
		}
		if (executeFollowingCode) {
			// if no error in Syntax is found
			saveEndpointOnNext();
		}
	};
	const replaceForSyntaxValidations = (body) => {
		let replacementBody = body.replace(/\s*{{\s*#each.*}}/g, '');
		replacementBody = replacementBody.replace(/,*\s*{{\/each}}/g, '');
		replacementBody = replacementBody.replace(/\s*{{\s*#set.*}}.*{{\/set}}/g, '');
		replacementBody = replacementBody.replace(/\s*{{\s*#cacheset.*}}.*{{\/cacheset}}/g, '');
		replacementBody = replacementBody.replace(/\s*{{\s*cacheget.*}}/g, '');

		replacementBody = replacementBody.replace(/\s*{{\s*#if.*}}/g, '');
		replacementBody = replacementBody.replace(/,*\s*{{\/if}}/g, '');
		replacementBody = replacementBody.replace(/\s*{{\s*#loop.*}}/g, '');
		replacementBody = replacementBody.replace(/,*\s*{{\/loop}}/g, '');
		replacementBody = replacementBody.replace(/['"]\s*{{.*}}\s*["']/g, '""');
		replacementBody = replacementBody.replace(/{{.*}}/g, '123');
		replacementBody = replacementBody.replace(/,\s*]/g, ']');
		replacementBody = replacementBody.replace(/,\s*,/g, ',');
		replacementBody = replacementBody.replace(/,+/g, ',');
		replacementBody = replacementBody.replace(/}\s*{/g, '},{');
		replacementBody = replacementBody.replace(/"\s+"/g, '","');
		return replacementBody;
	};
	const handleVersionChange = (e) => {
		let eTargetValue = e.target.value;
		swalBootstrapButtonsDangerousQuestion
			.fire({
				title: 'Are you sure?',
				text: 'Any pending changes will be discarded and selected version will be loaded',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				reverseButtons: true,
			})
			.then(async (result) => {
				if (result.value) {
					setLoading(true);
					setActivateCJV(false);
					let versionValue = eTargetValue.replace(/\(latest\)/g, '');
					let selectedObjectData =
						s3VersionDetails.result[s3VersionDetails.result[0].length - versionValue];
					simulationService
						.getObjectByVersion({
							keyValue: selectedObjectData.Key,
							versionId: selectedObjectData.VersionId,
						})
						.then((response) => {
							setSelectedVersion(eTargetValue);
							try {
								if (response.data && response.data.data) {
									let newVS = processVirtualService(response.data.data);
									setEndpointPairs(newVS.data.pairs);
									handleSetFEP(newVS.data.pairs[0]);
								}
							} catch (error) {
								// TODO handle this error
								console.log(error);
							}

							setLoading(false);
						})
						.catch(() => {
							setLoading(false);
						});
				}
			});

	};
	const checkTypeOfString = (inputBody) => {

		// returns if the body is of type json, or xml
		let inputValue = inputBody;
		inputValue = inputValue.trim();
		if (inputValue.startsWith('<?xml')) {
			return 'xml';
		}
		else if (inputValue[0] === '{' && inputValue[1] !== '{') {
			return 'json';
		}
		else {
			return 'other';
		}
	};
	const return_assie_date = (utc_date_data, index) => {
		var dateValue = moment(utc_date_data.LastModified);
		dateValue.tz('Australia/Sydney').format('ha z');
		let prefix = '';
		if (index === 0) {
			prefix = 'latest - ';
		}
		return prefix + moment(dateValue).format('DD/MM/YYYY HH:mm');
	};
	const renderVersionSelection = () => {
		return (
			<>
				<Row className="mt-2" hidden={(!stub_id) || (!isEditable)}>
					<Col xs="auto" className="d-flex align-items-center">
						<AddToolTip
							placement="top-start"
							title={Verbs.title.s3VersionSelection}
						>
							<InputLabel>Select version</InputLabel>
						</AddToolTip>
					</Col>
					<Col xs={3}>
						<Input
							type="select"
							bsSize="sm"
							name="s3VersionSelect"
							value={selectedVersion}
							onChange={(e) => handleVersionChange(e)}
						>

							{s3VersionDetails.result.map((dataItem, index) => {
								return <option key={index} value={dataItem.version}>
									{return_assie_date(dataItem, index)}
								</option>;
							})}
						</Input>
					</Col>
				</Row>
			</>
		);
	};

	const handleCloneEndpointClick = (rowData) => {
		const cloneEndpoint = () => {
			let clonedEndpoint = {
				request: rowData.request,
				response: rowData.response,
				tableData: { id: endpointPairs.length },
			};
			handleSetFEP(clonedEndpoint, false);
			let newEndpointPairs = _.cloneDeep(endpointPairs);
			newEndpointPairs.push(clonedEndpoint);
			setEndpointPairs(newEndpointPairs);
		};
		if (isAnEndpointFocussed) {
			swalBootstrapButtonsDangerousQuestion
				.fire({
					title: 'Are you sure?',
					text: 'Any pending changes will be discarded and endpoint will be cloned',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					cancelButtonText: 'No',
					reverseButtons: true,
				})
				.then(async (result) => {
					if (result.value) {
						cloneEndpoint();
					}
				});
		}
		else {
			cloneEndpoint();
		}

	};

	const handleDeleteEndpointClick = (rowData) => {
		swalBootstrapButtonsDangerousQuestion
			.fire({
				title: 'Are you sure?',
				text: 'You are about to delete the endpoint',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				reverseButtons: true,
			})
			.then(async (result) => {
				if (result.value) {
					let newEndpointPairs = endpointPairs.filter(
						(item, eindex) => eindex !== rowData.tableData.id
					);
					setIsAnEndpointFocussed(false);
					setFocussedEndpoint('');
					setEndpointPairs(newEndpointPairs);
				}
			});
	};

	const handleAddEndpointClick = () => {
		const addEndpointClick = () => {
			const newPair = _.cloneDeep(default_vs.data.pairs[0]);
			newPair.request.query = { '': [{ value: '', matcher: 'exact' }] };
			newPair.request.headers = {
				Svhost: [{ value: sVHostHeader, matcher: 'glob' }],
				'': [{ value: '', matcher: 'exact' }],
			};
			newPair.request.body = [{ value: '', matcher: 'exact' }];
			newPair.response.headers = { '': [''] };
			newPair.response.actions = [_.cloneDeep(default_response_action)];
			handleSetFEP({ ...newPair, tableData: { id: endpointPairs.length } }, true);
			setEndpointPairs([
				...endpointPairs,
				{ ...newPair, tableData: { id: endpointPairs.length } },
			]);
			setRevision(!revision);
		};
		if (isAnEndpointFocussed) {
			swalBootstrapButtonsDangerousQuestion
				.fire({
					title: 'Are you sure?',
					text: 'Any pending changes will be discarded and endpoint will be cloned',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					cancelButtonText: 'No',
					reverseButtons: true,
				})
				.then(async (result) => {
					if (result.value) {
						addEndpointClick();
					}
				});
		}
		else {
			addEndpointClick();
		}

	};
	const [isAnEndpointFocussed, setIsAnEndpointFocussed] = useState(false);



	const actions = [
		{
			icon: 'account_tree',

			iconProps: {
				style: {
					color: palette.blue,
					fontSize: '26px'
				},
			},
			tooltip: 'Customer Journey Virtualisation',
			position: 'row',
			hidden: (!stub_id || !isEditable),
			onClick: (event, rowData) => handleCJVTreeClick(rowData),
		},
		{
			icon: 'reorder',

			iconProps: {
				style: {
					color: palette.purple,
					fontSize: '26px'
				},
			},
			tooltip: 'Reorder  endpoint',
			position: 'row',
			hidden: !isEditable,
			onClick: (event, rowData) => handleMove(rowData),
		},
		{
			icon: 'file_copy',
			iconProps: {
				style: { color: palette.info },
			},
			tooltip: 'Clone endpoint',
			position: 'row',
			hidden: !isEditable,
			onClick: (event, rowData) => handleCloneEndpointClick(rowData),
		},
		{
			icon: 'delete',
			iconProps: {
				style: { color: palette.danger },
			},
			tooltip: 'Delete endpoint',
			position: 'row',
			hidden: !isEditable || endpointPairs.length === 1,
			onClick: (event, rowData) => handleDeleteEndpointClick(rowData),
		},
		{
			icon: 'add_circle',
			iconProps: { style: { fontSize: '35px', color: palette.primaryshade2 } },
			tooltip: 'Add endpoint',
			isFreeAction: true,
			hidden: !isEditable,
			onClick: () => {
				handleAddEndpointClick();
			},
		},
	];

	const [columns] = useState([

		{
			title: 'S. No.',
			width: 10,
			render: rowData => {
				return <span>{parseInt(`${rowData.tableData.id}`) + 1}</span>;
			}
		},
		{
			title: 'Request type',
			width: 10,
			render: rowData => {
				switch (rowData.request.requestType) {
					case 'HTTP': {
						return <span>{`${rowData.request.requestType} ${rowData.request.method[0].value}`}</span>;
					}
					case 'JMS':
					case 'Tibco':
					case 'CometD':
					case 'Kinesis':
					case 'IBMMQ':
					case 'SQS': {
						return <span>{`${rowData.request.requestType}`}</span>;
					}
				}
			},
		},
		{
			title: 'ID',
			field: 'endpointId',
		},
		{
			title: 'Name',
			field: 'request.path[0].value',
		},
		{
			title: 'Label',
			field: 'label'
		}
	]);
	const endpointRef = useRef(null);
	const endpointsTableRef = useRef(null);
	const filePreviewRef = useRef(null);
	const [focussedEndpoint, setFocussedEndpoint] = useState('');
	function moveEndpoint(arr, old_index, new_index) {
		if (new_index >= arr.length) {
			var k = new_index - arr.length;
			while ((k--) + 1) {
				arr.push(undefined);
			}
		}
		arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
		return arr;
	}
	const handleCJVTreeClick = (rowData) => {
		if (rowData.defaultCJVScenario) {

			setDefaultScenario(rowData.defaultCJVScenario);
		}
		setActivateCJV(true);
		setIsCJVFlowOpen(false);
		setIsAnEndpointFocussed(false);
		setShowCjvLoader(true);
		setIsOpen(true);
		if (rowData.endpointId) {
			setCJVMaterialLoader(true);

			CJVScenarioService.getCJVScenarios(rowData.endpointId, virtualServiceName, sVHostHeader)
				.then((cJVScenarios) => {
					//TODO dont do data.data
					setCJVFilesListS3(cJVScenarios.data);
					let endPtIndexAndId = {};
					if (rowData.endpointId) {
						endPtIndexAndId.endpointId = rowData.endpointId;
					}

					endPtIndexAndId.endpointIndex = rowData.tableData.id;
					setEndPointIndexAndId(endPtIndexAndId);

					setFocussedEndpoint(rowData);

					setCJVMaterialLoader(false);
				})
				.catch((error) => {
					history.push({
						pathname: 'errorPage',
						state: {
							errorMessage: `${errorResponses.GENERIC_ERROR}. ${error}`
						}
					});
				});
		}
		else {
			setCJVFilesListS3([]);
			setFocussedEndpoint(rowData);
			endpointRef.current.scrollIntoView();
			setCJVMaterialLoader(false);
			let endPtIndexAndId = {};
			if (rowData.endpointId) {
				endPtIndexAndId.endpointId = rowData.endpointId;
			}
			endPtIndexAndId.endpointIndex = rowData.tableData.id;
			setEndPointIndexAndId(endPtIndexAndId);
		}
	};
	const handleGenerateCJV = async () => {
		try {
			setIsCJVFlowOpen(false);
			setShowCjvDiagramLoader(true);
			setIsCJVGenerated(true);
			setShowCjvLoader(true);
			scrollToRef(endpointRef);
			setCjvFilename('');
			let inputValue = {};
			inputValue.stub_id = stub_id;
			if (endpointPairs[focussedEndpoint.tableData.id].endpointId) {
				inputValue.endpointId = endpointPairs[focussedEndpoint.tableData.id].endpointId;
			}
			else {
				inputValue.endpointIndex = focussedEndpoint.tableData.id;

			}
			inputValue.isParentNode = true;
			let responseGenerate = await CJVScenarioService.generateCJV(inputValue);
			let response = { cjvMapper: {}, uiDefinition: responseGenerate.data };

			setCjvElements(response);
			// TODO get rid of revision
			setRevision(!revision);
			setShowCjvLoader(false);
			setIsCJVFlowOpen(true);
			setShowCjvDiagramLoader(false);




		}
		catch (error) {
			history.push({
				pathname: 'errorPage',
				state: {
					errorMessage: errorResponses.GENERIC_ERROR
				}
			});

		}


	};

	const handleDeleteCJVClick = (rowData) => {
		// TODO: change logic according to defaultScenario state.
		if (rowData.Filename === defaultScenario) {
			Swal.fire({
				icon: 'warning',
				title: 'Default CJVs can\'t be deleted'
			});
		}
		else {
			swalBootstrapButtonsDangerousQuestion
				.fire({
					title: 'Are you sure?',
					text: 'You are about to delete the CJV',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Delete',
					cancelButtonText: 'Cancel',
					reverseButtons: true,
				})
				.then(async (result) => {
					if (result.value) {
						CJVScenarioService.deleteCJV({ s3_file_path: rowData.Key }).then(() => {

							setActivateCJV(false);
							setIsOpen(false);
							setIsCJVFlowOpen(false);
						});
					}
				});

		}

	};
	const handleMove = (rowData) => {
		Swal.fire({
			title: 'Reorder endpoint',
			text: 'Move  to  S. No.',

			input: 'text',

			inputAttributes: {
				autocapitalize: 'off',
				required: 'required'

			},
			showCancelButton: true,
			confirmButtonText: 'Reorder',
			showLoaderOnConfirm: true,
			inputValidator: (value) => {
				let parsedValue = parseInt(value);

				if (!parsedValue) {

					return 'Please enter a number';
				}
				if (parsedValue) {
					if (value > endpointPairs.length || parseInt(value) === 0) {
						return 'Please enter a value between 1 and ' + endpointPairs.length;
					}
				}
			},
			preConfirm: (value) => {
				let arr = _.cloneDeep(endpointPairs);
				let movedArr = moveEndpoint(arr, rowData.tableData.id, value - 1);
				setIsAnEndpointFocussed(false);
				setFocussedEndpoint('');
				setEndpointPairs(movedArr);

			}
		});
	};
	const handleSetFEP = (rowData, shouldBeScrolled) => {
		setIsAnEndpointFocussed(true);
		if (shouldBeScrolled) {
			setTimeout(() => scrollToRef(endpointRef), 400);
		}
		setIsOpen(false);
		setFocussedEndpoint(rowData);
		setFEPLabel(rowData.label);
		setFEPDescription(rowData.description);
		setFEPRequestType(rowData.request.requestType);
		setFEPRequestMethod(rowData.request.method);
		switch (rowData.request.requestType) {
			case 'HTTP': {
				if ((_.isEqual(rowData.response.actions, [default_response_action]))) {
					setAreFEPResponseActionsEnabled(false);
					setFEPResponseActions([_.cloneDeep(default_response_action)]);
				}
				else {
					setAreFEPResponseActionsEnabled(true);
					setFEPResponseActions(rowData.response.actions);
				}
				break;
			}
			case 'Kinesis':
			case 'Tibco':
			case 'CometD':
			case 'JMS':
			case 'IBMMQ':
			case 'SQS': {
				setAreFEPResponseActionsEnabled(true);
				if ((_.isEqual(rowData.response.actions, [default_response_action]))) {
					setFEPResponseActions([_.cloneDeep(default_response_action)]);
				}
				else {
					setFEPResponseActions(rowData.response.actions);
				}
				break;
			}
		}
		setFEPRequestPath(rowData.request.path);
		// setPathInitialValue(rowData.request.path);
		setFEPRequestQueryParamsList(
			Object.entries(rowData.request.query).flatMap(
				([key, value]) =>
					value.map(item => ({
						...item,
						querykey: key
					}))
			)
		);
		setFEPRequestHeadersList(
			Object.entries(rowData.request.headers)
				.map(([key, value]) => {
					value[0].headerkey = key;
					return value[0];
				})
				.filter((item) => item.headerkey !== 'Svhost')
		);
		setFEPRequestBody(rowData.request.body);
		setFEPResponseStatus(parseInt(rowData.response.status));
		setFEPResponseHeadersList(
			Object.entries(rowData.response.headers).map(([key, value]) => {
				return { headerkey: key, value: value[0] };
			})
		);
		if ('fixedDelay' in rowData.response) {
			setIsFEPResponseDelayEnabled(true);
			setFEPResponseDelay(rowData.response.fixedDelay);
		} else {
			setIsFEPResponseDelayEnabled(false);
			setFEPResponseDelay("");
		}
		setFEPResponseBody(rowData.response.body);
		setIsFEPResponseTemplated(rowData.response.templated);
	};
	const handleEndpointClick = (event, rowData) => {
		handleSetFEP(rowData, true);
	};
	/**
	 * FEP <-> Focussed Endpoint
	 */
	const [fEPLabel, setFEPLabel] = useState(default_vs_clone.data.pairs[0].label);
	const [fEPDescription, setFEPDescription] = useState(default_vs_clone.data.pairs[0].description);
	const handleFEPLabelUpdate = (e) => {
		setFEPLabel(e.target.value);
	};
	const handleFEPDescription = (e) => {
		setFEPDescription(e.target.value);
	};
	const [fEPRequestType, setFEPRequestType] = useState(
		default_vs_clone.data.pairs[0].request.requestType
	);
	const handleFEPRequestTypeUpdate = (requestType) => {
		setFEPRequestType(requestType);
		switch (requestType) {
			case 'Kinesis':
			case 'Tibco':
			case 'CometD':
			case 'JMS':
			case 'IBMMQ':
			case 'SQS': {
				setAreFEPResponseActionsEnabled(true);
				break;
			}
			case 'HTTP': {
				if ((_.isEqual(focussedEndpoint.response.actions, [default_response_action]))) {
					setAreFEPResponseActionsEnabled(false);
					setFEPResponseActions([_.cloneDeep(default_response_action)]);
				}
				else {
					setAreFEPResponseActionsEnabled(true);
					setFEPResponseActions(focussedEndpoint.response.actions);
				}
				break;
			}

		}
	};
	const [fEPRequestMethod, setFEPRequestMethod] = useState(
		default_vs_clone.data.pairs[0].request.method
	);

	const [fEPRequestPath, setFEPRequestPath] = useState(
		default_vs_clone.data.pairs[0].request.path
	);

	const [fEPRequestQueryParamsList, setFEPRequestQueryParamsList] = useState(
		Object.entries(default_vs_clone.data.pairs[0].request.query).flatMap(
			([key, value]) =>
				value.map(item => ({
					...item,
					querykey: key
				}))
		)
	);
	const [fEPRequestHeadersList, setFEPRequestHeadersList] = useState(
		Object.entries(default_vs_clone.data.pairs[0].request.headers).map(
			([key, value]) => {
				value[0].headerkey = key;
				return value[0];
			}
		)
	);
	const [fEPRequestBody, setFEPRequestBody] = useState(
		default_vs_clone.data.pairs[0].request.body
	);

	const [fEPResponseStatus, setFEPResponseStatus] = useState(
		default_vs_clone.data.pairs[0].response.status
	);
	const [fEPResponseHeadersList, setFEPResponseHeadersList] = useState(
		Object.entries(default_vs_clone.data.pairs[0].response.headers).map(
			([key, value]) => {
				return { headerkey: key, value: value[0] };
			}
		)
	);
	const [isFEPResponseDelayEnabled, setIsFEPResponseDelayEnabled] = useState(
		false
	);
	const [fEPResponseDelay, setFEPResponseDelay] = useState("");

	const [fEPResponseBody, setFEPResponseBody] = useState(
		default_vs_clone.data.pairs[0].response.body
	);
	const [isFEPResponseTemplated, setIsFEPResponseTemplated] = useState(
		default_vs_clone.data.pairs[0].response.templated
	);
	let history = useHistory();

	const [
		areFEPResponseActionsEnabled,
		setAreFEPResponseActionsEnabled,
	] = useState(false);
	const [
		areFEPResponseActionsExpanded,
		setAreFEPResponseActionsExpanded,
	] = useState(true);
	const handleActionsCollapseBtnClick = () => {
		setAreFEPResponseActionsExpanded(!areFEPResponseActionsExpanded);
	};
	const [fEPResponseActions, setFEPResponseActions] = useState(
		default_vs_clone.data.pairs[0].response.actions
	);

	/**
	 * Renders the table of endpoint pairs
	 */
	const renderEndpoints = () => {
		return (
			<div ref={endpointsTableRef}>
				<Container className="pl-0 pr-0 mt-3">
					<MaterialTable
						title={<h5>{`${virtualServiceName.replace('.json', '')}`}</h5>}
						columns={columns}
						data={endpointPairs}
						actions={actions}
						options={{
							rowStyle: (rowData) => ({
								backgroundColor:
									focussedEndpoint &&
										focussedEndpoint.tableData.id === rowData.tableData.id
										? palette.blueseq[0]
										: '#FFF',
							}),
							headerStyle: {
								fontWeight: 'bold',
								backgroundColor: palette.blueseq[3],
							},
							paging: endpointPairs.length > 5,
							actionsColumnIndex: -1,
						}}
						localization={{
							header: {
								actions: (isEditable && !isAnEndpointFocussed) ? 'Actions' : '',
							},
						}}
						onRowClick={(event, rowData) => handleEndpointClick(event, rowData)}
					/>
				</Container>

			</div>

		);
	};

	const handleFEPRequestMethodUpdate = (method) => {
		setFEPRequestMethod([{ value: method, matcher: 'exact' }]);
	};
	// function useDebounceRequestPath(delay) {
	// 	const cb = fEPRequestPath[0].value ? fEPRequestPath[0].value : null;
	// 	const [debounceValue, setDebounceValue] = useState(cb);
	// 	useEffect(() => {
	// 	  const handler = setTimeout(() => {
	// 		setDebounceValue(cb);
	// 	  }, delay);

	// 	  return () => {
	// 		clearTimeout(handler);
	// 	  };
	// 	}, [cb, delay]);
	// 	return debounceValue;
	// }

	// const debounceValue = useDebounceRequestPath(2000);

	// const checkendpointexists= async (eTargetValue) =>{
	// 	try{
	// 		setLoading(true);
	// 		const endpointexists = await simulationService.checkendpointexists(eTargetValue, fEPRequestMethod[0].value, sVHostHeader);
	// 		if (endpointexists && endpointexists.status === 200 && endpointexists.data && endpointexists.data.data) {
	// 			console.log(endpointexists.data.data);
	// 			setLoading(false);
	// 			swalBootstrapButtonsDangerousQuestion
	// 				.fire({
	// 					title: 'Stub or mock may have duplicate URL',
	// 					text: 'Click Yes to Verify, Any changes made will be discarded',
	// 					icon: 'warning',
	// 					showCancelButton: true,
	// 					confirmButtonText: 'Yes',
	// 					cancelButtonText: 'No',
	// 					reverseButtons: true,
	// 				})
	// 				.then(async (result) => {
	// 					if (result.value) {
	// 						let pathname = '/library';
	// 						if(user_role == 'support'){
	// 							pathname = '/support/library';
	// 						}
	// 						history.push({
	// 							pathname: pathname,
	// 							state: {
	// 								s3_path: endpointexists.data.data.Key
	// 							},
	// 						});
	// 					}
	// 				});
	// 		}
	// 		setLoading(false);
	// 	}catch(error){
	// 		setLoading(false);
	// 		console.error(error);
	// 		console.error('Error in checkendpointexists');
	// 	}
	// }

	// const [pathInitialValue, setPathInitialValue] = useState(fEPRequestPath[0].value);

	// useEffect( () => {
	// 	if(pathInitialValue === '/' || pathInitialValue === debounceValue) {
	// 		//setIsPathExist(false);
	// 	} else if (checkPathExistFirstTime) {
	// 		checkendpointexists(debounceValue);	
	// 	}

	// }, [debounceValue]);

	const handleFEPRequestPathUpdate = (e) => {
		let eTargetValue = e.target.value;
		let eTargetName = e.target.name;
		setCheckPathExistFirstTime(true);
		switch (eTargetName) {
			case 'kinesisNameMatcher':
			case 'jmsNameMatcher':
			case 'tibcoNameMatcher':
			case 'cometDNameMatcher':
			case 'requestPathMatcher':
			case 'ibmmqNameMatcher':
			case 'sqsNameMatcher': {
				setFEPRequestPath([
					{ ...fEPRequestPath[0], matcher: eTargetValue },
				]);
				break;
			}
			case 'kinesisNameValue':
			case 'jmsNameValue':
			case 'tibcoNameValue':
			case 'cometDNameValue':
			case 'requestPathValue':
			case 'ibmmqNameValue':
			case 'sqsNameValue': {
				setFEPRequestPath([
					{ ...fEPRequestPath[0], value: eTargetValue },
				]);
				break;
			}
			case 'jsmType': {
				setFEPRequestPath([
					{ ...fEPRequestPath[0], jsmType: eTargetValue },
				]);
				break;
			}
			case 'tibcoType': {
				setFEPRequestPath([
					{ ...fEPRequestPath[0], tibcoType: eTargetValue },
				]);
				break;
			}
		}
	};
	const handleFEPRequestBodyUpdate = (e) => {
		let eTargetName = e.target.name;
		let eTargetValue = e.target.value;
		switch (eTargetName) {
			case 'kinesisMessageMatcher':
			case 'jmsMessageMatcher':
			case 'tibcoMessageMatcher':
			case 'cometDMessageMatcher':
			case 'requestBodyMatcher':
			case 'ibmmqMessageMatcher':
			case 'sqsMessageMatcher': {
				setFEPRequestBody([
					{ value: fEPRequestBody[0].value, matcher: eTargetValue },
				]);
				break;
			}
			case 'kinesisMessageValue':
			case 'jmsMessageValue':
			case 'tibcoMessageValue':
			case 'cometDMessageValue':
			case 'requestBodyValue':
			case 'ibmmqMessageValue':
			case 'sqsMessageValue': {
				setFEPRequestBody([
					{ value: eTargetValue, matcher: fEPRequestBody[0].matcher },
				]);
				break;

			}
		}
	};
	const handleRemoveReqQueryButton = (index) => {
		let newFEPRequestQueryParamsList = fEPRequestQueryParamsList.filter(
			(item, tindex) => tindex !== index
		);
		setFEPRequestQueryParamsList(newFEPRequestQueryParamsList);
	};
	const handleAddReqQueryButton = () => {
		setFEPRequestQueryParamsList([
			...fEPRequestQueryParamsList,
			{ querykey: '', value: '', matcher: 'exact' },
		]);
	};
	const handleFEPRequestQueryParamsUpdate = (e, index) => {
		let newFEPRequestQueryParamsList = fEPRequestQueryParamsList;
		if (e.target.name === 'requestQueryMatcher') {
			newFEPRequestQueryParamsList[index].matcher = e.target.value;
		} else if (e.target.name === 'requestQueryKey') {
			newFEPRequestQueryParamsList[index].querykey = e.target.value;
		} else if (e.target.name === 'requestQueryValue') {
			newFEPRequestQueryParamsList[index].value = e.target.value;
		}
		setFEPRequestQueryParamsList(
			_.cloneDeep(newFEPRequestQueryParamsList)
		);
		setRevision(!revision);
	};
	const handleRemoveReqHeaderButton = (index) => {
		let newFEPRequestHeadersList = fEPRequestHeadersList.filter(
			(item, tindex) => tindex !== index
		);
		setFEPRequestHeadersList(newFEPRequestHeadersList);
	};
	const handleAddReqHeaderButton = () => {
		setFEPRequestHeadersList([
			...fEPRequestHeadersList,
			{ headerkey: '', value: '', matcher: 'exact' },
		]);
	};
	const handleFEPRequestHeadersUpdate = (e, index) => {
		let newFEPRequestHeadersList = fEPRequestHeadersList;
		if (e.target.name === 'requestHeaderMatcher') {
			newFEPRequestHeadersList[index].matcher = e.target.value;
		} else if (e.target.name === 'requestHeaderKey') {
			newFEPRequestHeadersList[index].headerkey = e.target.value;
		} else if (e.target.name === 'requestHeaderValue') {
			newFEPRequestHeadersList[index].value = e.target.value;
		}
		setFEPRequestHeadersList(
			_.cloneDeep(newFEPRequestHeadersList)
		);
		setRevision(!revision);
	};
	const renderFEPRequestQueryParams = () => {
		return (
			<>
				{/* Query parameters */}
				<Row className="mt-3">
					<Col xs="auto">
						<AddToolTip placement="top-start" title={Verbs.title.query}>
							<InputLabel>Query parameters</InputLabel>
						</AddToolTip>
					</Col>
				</Row>
				{fEPRequestQueryParamsList.map((item, index) => {
					return (
						<Row className="mt-2" key={index}>
							<Col xs={3}>
								<Input
									placeholder="Key"
									bsSize="sm"
									value={item.querykey}
									name="requestQueryKey"
									onChange={(e) =>
										handleFEPRequestQueryParamsUpdate(e, index)
									}
								></Input>
							</Col>
							<Col xs={3}>
								<Input
									type="select"
									bsSize="sm"
									name="requestQueryMatcher"
									value={item.matcher}
									onChange={(e) =>
										handleFEPRequestQueryParamsUpdate(e, index)
									}
								>
									{matchers.map((value) => {
										return <option key={value}>{value}</option>;
									})}
								</Input>
							</Col>
							<Col xs={4} className="ml-0">
								<Input
									placeholder="Value"
									bsSize="sm"
									name="requestQueryValue"
									value={item.value}
									onChange={(e) =>
										handleFEPRequestQueryParamsUpdate(e, index)
									}
								></Input>
							</Col>
							{fEPRequestQueryParamsList.length === 1 ? null : (
								<Col xs="auto" className="pl-1 pr-1">
									<IconButton
										aria-label="remove query"
										component="span"
										className="p-0 pt-1"
										onClick={() => handleRemoveReqQueryButton(index)}
										hidden={!isEditable}
									>
										<RemoveCircle
											fontSize="small"
											style={{ color: palette.danger }}
										/>
									</IconButton>
								</Col>
							)}
							{index === fEPRequestQueryParamsList.length - 1 &&
								!hasExceededQueryRows(fEPRequestQueryParamsList.length) ? (
								<Col xs="auto" className="pl-1 pr-1">
									<IconButton
										aria-label="add query"
										component="span"
										className="p-0 pt-1"
										onClick={handleAddReqQueryButton}
										hidden={!isEditable}
									>
										<AddCircle fontSize="small" color="secondary" />
									</IconButton>
								</Col>
							) : null}
						</Row>
					);
				})}
				{hasExceededQueryRows(fEPRequestQueryParamsList.length) && (
					<Row className="text-center mt-3">
						<Col className="text-center">
							<span className="text-danger" style={{ fontSize: 15 }}>
								<FaExclamationCircle
									className="mr-2"
									size="1.1rem"
									style={{ marginBottom: '0.1rem' }}
								/>
								{Verbs.validationMessages.queryListLengthInvalid}
							</span>
						</Col>
					</Row>
				)}
			</>
		);
	};
	const renderFEPRequestHeaders = () => {
		return (
			<>
				{/* Headers */}
				<Row className="mt-3">
					<Col xs="auto">
						<AddToolTip
							placement="top-start"
							title={Verbs.title.requestHeader}
						>
							<InputLabel>Headers</InputLabel>
						</AddToolTip>
					</Col>
				</Row>
				{fEPRequestHeadersList.map((item, index) => {
					return (
						<Row
							className="mt-2"
							key={index}
							hidden={item.headerkey === 'Svhost'}
						>
							<Col xs={3}>
								<Input
									placeholder="Key"
									bsSize="sm"
									value={item.headerkey ? item.headerkey : ''}
									name="requestHeaderKey"
									onChange={(e) => handleFEPRequestHeadersUpdate(e, index)}
								></Input>
							</Col>
							<Col xs={3}>
								<Input
									type="select"
									bsSize="sm"
									name="requestHeaderMatcher"
									value={item.matcher}
									onChange={(e) => handleFEPRequestHeadersUpdate(e, index)}
								>
									{matchers.map((value) => {
										return <option key={value}>{value}</option>;
									})}
								</Input>
							</Col>
							<Col xs={4} className="ml-0">
								<Input
									placeholder="Value"
									bsSize="sm"
									value={item.value ? item.value : ''}
									name="requestHeaderValue"
									onChange={(e) => handleFEPRequestHeadersUpdate(e, index)}
								></Input>
							</Col>
							{fEPRequestHeadersList.length === 1 ? null : (
								<Col xs="auto" className="pl-1 pr-1">
									<IconButton
										aria-label="remove header"
										component="span"
										className="p-0 pt-1"
										onClick={() => handleRemoveReqHeaderButton(index)}
										hidden={!isEditable}
									>
										<RemoveCircle
											fontSize="small"
											style={{ color: palette.danger }}
										/>
									</IconButton>
								</Col>
							)}
							{index === fEPRequestHeadersList.length - 1 &&
								!hasExceededRequestHeaderRows(
									fEPRequestHeadersList.length
								) ? (
								<Col xs="auto" className="pl-1 pr-1">
									<IconButton
										aria-label="add header"
										component="span"
										className="p-0 pt-1"
										onClick={handleAddReqHeaderButton}
										hidden={!isEditable}
									>
										<AddCircle fontSize="small" color="secondary" />
									</IconButton>
								</Col>
							) : null}
						</Row>
					);
				})}
				{hasExceededRequestHeaderRows(fEPRequestHeadersList.length) && (
					<Row className="text-center mt-3">
						<Col className="text-center">
							<span className="text-danger" style={{ fontSize: 15 }}>
								<FaExclamationCircle
									className="mr-2"
									size="1.1rem"
									style={{ marginBottom: '0.1rem' }}
								/>
								{Verbs.validationMessages.headerListLengthInvalid}
							</span>
						</Col>
					</Row>
				)}
			</>
		);
	};
	const renderHTTPForm = () => {
		return (
			<>
				{/* Method */}
				<Row className="mt-3">
					<Col xs="auto">
						<AddToolTip placement="top-start" title={Verbs.title.method}>
							<InputLabel className="asterisk">Method</InputLabel>
						</AddToolTip>
					</Col>
				</Row>
				<Row className="mt-2">
					<Col>
						<Input
							type="select"
							name="requestMethodSelect"
							bsSize="sm"
							value={fEPRequestMethod[0].value}
							onChange={(e) => handleFEPRequestMethodUpdate(e.target.value)}
						>
							{http_methods.map((item) => {
								return <option key={item}>{item}</option>;
							})}
						</Input>
					</Col>
				</Row>
				{/* Path */}
				<Row className="mt-3">
					<Col xs="auto">
						<AddToolTip placement="top-start" title={Verbs.title.path}>
							<InputLabel className="asterisk">Path</InputLabel>
						</AddToolTip>
					</Col>
				</Row>
				<Row className="mt-2">
					<Col xs={3}>
						<Input
							type="select"
							bsSize="sm"
							name="requestPathMatcher"
							value={fEPRequestPath[0].matcher}
							onChange={(e) => handleFEPRequestPathUpdate(e)}
						>
							{matchers.map((item) => {
								return <option key={item}>{item}</option>;
							})}
						</Input>
					</Col>
					<Col xs={9}>
						<Input
							invalid={isPathInvalid(fEPRequestPath[0].value)}
							placeholder="/api"
							bsSize="sm"
							name="requestPathValue"
							value={fEPRequestPath[0].value}
							onChange={(e) => handleFEPRequestPathUpdate(e)}
						></Input>
						<FormFeedback invalid>
							{Verbs.validationMessages.pathInvalid}
						</FormFeedback>
					</Col>
				</Row>
				{renderFEPRequestQueryParams()}
				{renderFEPRequestHeaders()}
				{/* Body */}
				<Row className="mt-3">
					<Col xs="auto">
						<AddToolTip placement="top-start" title={Verbs.title.requestBody}>
							<InputLabel>Body</InputLabel>
						</AddToolTip>
					</Col>
				</Row>
				<Row className="mt-2">
					<Col>
						<Input
							type="select"
							bsSize="sm"
							name="requestBodyMatcher"
							value={fEPRequestBody[0].matcher}
							onChange={(e) => handleFEPRequestBodyUpdate(e)}
						>
							{http_body_matchers.map((item) => {
								return <option key={item}>{item}</option>;
							})}
						</Input>
					</Col>
				</Row>
				<Row className="mt-2">
					<Col>
						<Input
							type="textarea"
							bsSize="sm"
							maxLength={MAX_BODY_SIZE}
							rows="4"
							name="requestBodyValue"
							value={fEPRequestBody[0].value}
							onChange={(e) => handleFEPRequestBodyUpdate(e)}
						></Input>
					</Col>
				</Row>
			</>
		);
	};
	const handleFEPResponseStatusUpdate = (e) => {
		setFEPResponseStatus(parseInt(e.target.value));
	};
	const handleIsFEPResponseTemplatedChange = (checked) => {
		setIsFEPResponseTemplated(checked);
	};
	const handleAreFEPResponseActionsEnabled = (checked) => {
		setAreFEPResponseActionsEnabled(checked);
	};
	const handleIsFEPResponseDelayEnabledChange = (checked) => {
		setFEPResponseDelay("");
		setIsFEPResponseDelayEnabled(checked);
	};
	const handleFEPResponseDelayUpdate = (e) => {
		console.log("Set It");
		setFEPResponseDelay(e.target.value);
	};
	const handleFEPResponseBodyUpdate = (e) => {
		setFEPResponseBody(e.target.value);
	};
	const removeDefaultCJV = () => {


		try {
			let res = endpointPairs.map((pair) => {

				if (pair.tableData.id === focussedEndpoint.tableData.id) {
					let newPair = _.cloneDeep(focussedEndpoint);
					delete newPair.defaultCJVScenario;
					return newPair;
				}
				else {
					return pair;
				}
			});
			setEndpointPairs(res);
			setIsOpen(false);

			setIsCJVFlowOpen(false);
			return res;
		}
		catch (error) {
			history.push({
				pathname: 'errorPage',
				state: {
					errorMessage: errorResponses.GENERIC_ERROR
				}
			});

		}
	};
	const setDefaultCJVInVS = (cjvFileNameInput) => {

		try {
			let res = endpointPairs.map((pair) => {

				if (pair.tableData.id === focussedEndpoint.tableData.id) {
					let newPair = _.cloneDeep(focussedEndpoint);
					newPair.defaultCJVScenario = cjvFileNameInput;
					return newPair;
				}
				else {
					return pair;
				}
			});
			setEndpointPairs(res);
			setIsOpen(false);

			setIsCJVFlowOpen(false);
			return res;
		}
		catch (error) {
			history.push({
				pathname: 'errorPage',
				state: {
					errorMessage: errorResponses.GENERIC_ERROR
				}
			});

		}

	};
	const renderFEPRequestForm = () => {
		switch (fEPRequestType) {
			case 'HTTP':
				return renderHTTPForm();
			case 'JMS': {
				return (
					<JMS
						handleFEPRequestMethodUpdate={handleFEPRequestMethodUpdate}
						fEPRequestPath={fEPRequestPath}
						handleFEPRequestPathUpdate={handleFEPRequestPathUpdate}
						fEPRequestBody={fEPRequestBody}
						handleFEPRequestBodyUpdate={handleFEPRequestBodyUpdate}
						handleAreFEPResponseActionsEnabled={handleAreFEPResponseActionsEnabled}
					/>
				);
			}
			case 'Tibco': {
				return (
					<Tibco
						handleFEPRequestMethodUpdate={handleFEPRequestMethodUpdate}
						fEPRequestPath={fEPRequestPath}
						handleFEPRequestPathUpdate={handleFEPRequestPathUpdate}
						fEPRequestBody={fEPRequestBody}
						handleFEPRequestBodyUpdate={handleFEPRequestBodyUpdate}
						handleAreFEPResponseActionsEnabled={handleAreFEPResponseActionsEnabled}
					/>
				);
			}
			case 'CometD': {
				return (
					<CometD
						handleFEPRequestMethodUpdate={handleFEPRequestMethodUpdate}
						fEPRequestPath={fEPRequestPath}
						handleFEPRequestPathUpdate={handleFEPRequestPathUpdate}
						fEPRequestBody={fEPRequestBody}
						handleFEPRequestBodyUpdate={handleFEPRequestBodyUpdate}
						handleAreFEPResponseActionsEnabled={handleAreFEPResponseActionsEnabled}
					/>
				);
			}
			case 'Kinesis': {
				return (
					<Kinesis
						handleFEPRequestMethodUpdate={handleFEPRequestMethodUpdate}
						fEPRequestPath={fEPRequestPath}
						handleFEPRequestPathUpdate={handleFEPRequestPathUpdate}
						fEPRequestBody={fEPRequestBody}
						handleFEPRequestBodyUpdate={handleFEPRequestBodyUpdate}
						handleAreFEPResponseActionsEnabled={handleAreFEPResponseActionsEnabled}
					/>
				);
			}
			case 'IBMMQ': {
				return (
					<IBMMQ
						handleFEPRequestMethodUpdate={handleFEPRequestMethodUpdate}
						fEPRequestPath={fEPRequestPath}
						handleFEPRequestPathUpdate={handleFEPRequestPathUpdate}
						fEPRequestBody={fEPRequestBody}
						handleFEPRequestBodyUpdate={handleFEPRequestBodyUpdate}
						handleAreFEPResponseActionsEnabled={handleAreFEPResponseActionsEnabled}
					/>
				);
			}
			case 'SQS': {
				return (
					<SQS
						handleFEPRequestMethodUpdate={handleFEPRequestMethodUpdate}
						fEPRequestPath={fEPRequestPath}
						handleFEPRequestPathUpdate={handleFEPRequestPathUpdate}
						fEPRequestBody={fEPRequestBody}
						handleFEPRequestBodyUpdate={handleFEPRequestBodyUpdate}
						handleAreFEPResponseActionsEnabled={handleAreFEPResponseActionsEnabled}
					/>
				)
			}


		}
	};


	const renderFEPResponseForm = () => {
		const renderFEPResponseHeaders = () => {
			const handleRemoveResHeaderButton = (index) => {
				let newFEPResponseHeadersList = fEPResponseHeadersList.filter(
					(item, tindex) => tindex !== index
				);
				setFEPResponseHeadersList(newFEPResponseHeadersList);
			};
			const handleAddResHeaderButton = () => {
				setFEPResponseHeadersList([
					...fEPResponseHeadersList,
					{ headerkey: '', value: '' },
				]);
			};
			const handleFEPResponseHeadersUpdate = (e, index) => {
				let newFEPResponseHeadersList = fEPResponseHeadersList;
				if (e.target.name === 'responseHeaderKey') {
					newFEPResponseHeadersList[index].headerkey = e.target.value;
				} else if (e.target.name === 'responseHeaderValue') {
					newFEPResponseHeadersList[index].value = e.target.value;
				}
				setFEPResponseHeadersList(
					_.cloneDeep(newFEPResponseHeadersList)
				);
				setRevision(!revision);
			};
			return (
				<>
					{/* Response headers */}
					<Row className="mt-3">
						<Col xs="auto">
							<AddToolTip
								placement="top-start"
								title={Verbs.title.responseHeader}
							>
								<InputLabel>Headers</InputLabel>
							</AddToolTip>
						</Col>
					</Row>
					{fEPResponseHeadersList.map((item, index) => {
						return (
							<Row className="mt-2" key={index}>
								<Col xs={5}>
									<Input
										placeholder="Key"
										bsSize="sm"
										value={item.headerkey}
										name="responseHeaderKey"
										onChange={(e) => handleFEPResponseHeadersUpdate(e, index)}
									></Input>
								</Col>
								<Col xs={5}>
									<Input
										placeholder="Value"
										bsSize="sm"
										name="responseHeaderValue"
										value={item.value}
										onChange={(e) => handleFEPResponseHeadersUpdate(e, index)}
									></Input>
								</Col>
								{fEPResponseHeadersList.length === 1 ? null : (
									<Col xs="auto" className="pl-1 pr-1">
										<IconButton
											aria-label="remove header"
											component="span"
											className="p-0 pt-1"
											onClick={() => handleRemoveResHeaderButton(index)}
											hidden={!isEditable}
										>
											<RemoveCircle
												fontSize="small"
												style={{ color: palette.danger }}
											/>
										</IconButton>
									</Col>
								)}
								{index === fEPResponseHeadersList.length - 1 ? (
									<Col xs="auto" className="pl-1 pr-1">
										<IconButton
											aria-label="add header"
											component="span"
											className="p-0 pt-1"
											onClick={handleAddResHeaderButton}
											hidden={!isEditable}
										>
											<AddCircle fontSize="small" color="secondary" />
										</IconButton>
									</Col>
								) : null}
							</Row>
						);
					})}
					{hasExceededResponseHeaderRows(fEPResponseHeadersList.length) && (
						<Row className="text-center mt-3">
							<Col className="text-center">
								<span className="text-danger" style={{ fontSize: 15 }}>
									<FaExclamationCircle
										className="mr-2"
										size="1.1rem"
										style={{ marginBottom: '0.1rem' }}
									/>
									{Verbs.validationMessages.responseHeaderListLengthInvalid}
								</span>
							</Col>
						</Row>
					)}
				</>
			);
		};

		const renderFEPResponseActions = () => {
			const handleFEPResponseActionsUpdate = (e, index) => {
				let newActions = fEPResponseActions;
				switch (e.target.name) {
					case 'actionType':
						newActions[index].actionType = e.target.value;
						break;
					case 'actionTemplated':
						newActions[index].templated = e.target.value;
						if (e.target.value) {
							if (newActions[index].actionType === 'httpForward') {
								newActions[index].actionData.httpMethod = null;
							}
						} else {
							newActions[index].actionData.httpMethod = "GET";
						}
						break;
					case 'actionCondition':
						newActions[index].condition = e.target.value;
						break;
					case 'actionAsync':
						newActions[index].synchronous = e.target.value;
						if (newActions[index].synchronous) {
							delete newActions[index].delay;
							delete newActions[index].delaySeconds;
						}
						else {
							newActions[index].delay = false;
							newActions[index].delaySeconds = 0;
						}
						break;
					case 'actionAsyncDelay':
						newActions[index].delay = e.target.value;
						if (newActions[index].delay) {
							newActions[index].delaySeconds = 0;
						}
						else {
							delete newActions[index].delaySeconds;
						}
						break;
					case 'actionAsyncDelaySeconds':
						newActions[index].delaySeconds = ("string" === typeof e.target.value) ? e.target.value : parseInt(e.target.value);
						break;
					case 'actionForEach':
						newActions[index].forEach = e.target.value;
						break;
					case 'actionLabel':
						newActions[index].label = e.target.value;
				}
				setFEPResponseActions(newActions);
				setRevision(!revision);
			};
			const handleFEPHTTPResponseActionsUpdate = (e, index, hindex) => {
				let newActions = fEPResponseActions;
				switch (e.target.name) {
					case 'httpMethod':
						newActions[index].actionData.httpMethod = e.target.value;
						break;
					case 'mASSLSwitch':
						newActions[index].actionData.massl = e.target.value;
						if (newActions[index].actionData.massl) {
							if (mASSLCertAliases.length) {
								newActions[index].actionData.masslAlias = mASSLCertAliases[0].alias;
								newActions[index].actionData.masslPassword = '';
							}
						}
						else {
							delete newActions[index].actionData.masslAlias;
							delete newActions[index].actionData.masslPassword;
						}
						break;
					case 'mASSLCertAlias':
						newActions[index].actionData.masslAlias = e.target.value;
						break;
					case 'masslPassword':
						newActions[index].actionData.masslPassword = e.target.value;
						break;
					case 'httpUrl':
						newActions[index].actionData.httpUrl = e.target.value;
						break;
					case 'queryKey':
						newActions[index].actionData.params[hindex].querykey =
							e.target.value;
						break;
					case 'queryValue':
						newActions[index].actionData.params[hindex].value = e.target.value;
						break;
					case 'removeQuery':
						newActions[index].actionData.params = newActions[
							index
						].actionData.params.filter((item, indexValue) => indexValue !== hindex);
						break;
					case 'addQuery':
						newActions[index].actionData.params[hindex + 1] = {
							querykey: '',
							value: '',
						};
						break;
					case 'headerKey':
						newActions[index].actionData.headers[hindex].headerkey =
							e.target.value;
						break;
					case 'headerValue':
						newActions[index].actionData.headers[hindex].value = e.target.value;
						break;
					case 'removeHeader':
						newActions[index].actionData.headers = newActions[
							index
						].actionData.headers.filter((item, indexValue) => indexValue !== hindex);
						break;
					case 'addHeader':
						newActions[index].actionData.headers[hindex + 1] = {
							headerkey: '',
							value: '',
						};
						break;
					case 'body':
						newActions[index].actionData.body = e.target.value;
						break;
				}
				setFEPResponseActions(newActions);
				setRevision(!revision);
			};
			const handleFEPKinesisResponseActionsUpdate = (e, index) => {
				let newActions = _.cloneDeep(fEPResponseActions);
				switch (e.target.name) {
					case 'protocolProxyURL':
						newActions[index].actionData.protocolProxyURL = e.target.value;
						break;
					case 'streamName':
						newActions[index].actionData.streamName = e.target.value;
						break;
					case 'streamMessage':
						newActions[index].actionData.streamMessage = e.target.value;
						break;
					case 'partitionKey':
						newActions[index].actionData.partitionKey = e.target.value;
						break;
					case 'authorization':
						newActions[index].actionData.authorization = e.target.value;
						break;
					case 'contentType':
						newActions[index].actionData.contentType = e.target.value;
						break;
					default:
						break;
				}
				setFEPResponseActions(newActions);
				setRevision(!revision);
			};
			const handleFEPExecuteJSResponseActionsUpdate = (e, index) => {
				let newActions = _.cloneDeep(fEPResponseActions);
				switch (e.target.name) {
					case 'script':
						newActions[index].actionData.headers = [{ headerkey: 'workspace', value: sVHostHeader.replace('.*', '') }, { headerkey: 'updatedBy', value: userDetails.user_id }];
						newActions[index].actionData.script = e.target.value;
						break;
					default:
						break;
				}
				setFEPResponseActions(newActions);
				setRevision(!revision);
			};
			const handleFEPPropertiesResponseActionsUpdate = (e, index) => {
				let newActions = _.cloneDeep(fEPResponseActions);
				switch (e.target.name) {
					case 'body':
						newActions[index].actionData.body = e.target.value;
						break;
					default:
						break;
				}
				setFEPResponseActions(newActions);
				setRevision(!revision);
			};
			const handleFEPDatabaseResponseActionsUpdate = (e, index) => {
				let newActions = _.cloneDeep(fEPResponseActions);
				switch (e.target.name) {
					case 'protocolProxyURL':
						newActions[index].actionData.protocolProxyURL = e.target.value;
						break;
					case 'activityType':
						newActions[index].actionData.activityType = e.target.value;
						break;
					case 'query':
						newActions[index].actionData.query = e.target.value;
						break;
					case 'authorization':
						newActions[index].actionData.authorization = e.target.value;
						break;
					case 'contentType':
						newActions[index].actionData.contentType = e.target.value;
						break;
					default:
						break;
				}
				setFEPResponseActions(newActions);
			};

			const handleFEPTibcoSQSorJMSResponseActionsUpdate = (e, index, hindex) => {
				let newActions = fEPResponseActions;
				switch (e.target.name) {
					case 'protocolProxyURL':
						newActions[index].actionData.protocolProxyURL = e.target.value;
						break;
					case 'qOrTName':
						newActions[index].actionData.qOrTName = e.target.value;
						break;
					case 'qOrTMessage':
						newActions[index].actionData.qOrTMessage = e.target.value;
						break;
					case 'authorization':
						newActions[index].actionData.authorization = e.target.value;
						break;
					case 'contentType':
						newActions[index].actionData.contentType = e.target.value;
						break;
					case 'headerKey':
						newActions[index].actionData.headers[hindex].headerkey =
							e.target.value;
						break;
					case 'headerValue':
						newActions[index].actionData.headers[hindex].value = e.target.value;
						break;
					case 'removeHeader':
						newActions[index].actionData.headers = newActions[
							index
						].actionData.headers.filter((item, indexValue) => indexValue !== hindex);
						break;
					case 'addHeader':
						newActions[index].actionData.headers[hindex + 1] = {
							headerkey: '',
							value: '',
						};
						break;
					case 'fromAddress':
						newActions[index].actionData.fromAddress = e.target.value;
						break;
					case 'toAddress':
						newActions[index].actionData.toAddress = e.target.value;
						break;
					case 'emailBody':
						newActions[index].actionData.emailBody = e.target.value;
						break;
					case 'emailAttachments':
						newActions[index].actionData.emailAttachments = e.target.value;
						break;
					case 'subject':
						newActions[index].actionData.subject = e.target.value;
						break;
				}
				setFEPResponseActions(newActions);
				setRevision(!revision);

			}

			const handleFEPSQSorJMSResponseActionsUpdate = (e, index, hindex) => {
				handleFEPTibcoSQSorJMSResponseActionsUpdate(e, index, hindex);

			};

			const handleFEPTibcoResponseActionsUpdate = (e, index, hindex) => {
				handleFEPTibcoSQSorJMSResponseActionsUpdate(e, index, hindex);
			};

			const handleFEPSMTPResponseActionsUpdate = (e, index, hindex) => {
				handleFEPTibcoSQSorJMSResponseActionsUpdate(e, index, hindex);
			};
			const handleRemoveResAction = (aindex) => {
				setFEPResponseActions(
					fEPResponseActions.filter((item, index) => index !== aindex)
				);
			};
			const handleAddResAction = () => {
				setFEPResponseActions([
					...fEPResponseActions,
					_.cloneDeep(default_response_action),
				]);
			};

			const reorderResponseActions = (responseActions, newIndex, oldIndex) => {
				responseActions.splice(Number(newIndex) - 1, 0, responseActions.splice(oldIndex, 1)[0]);
				return responseActions;
			}

			const cloneResponseAction = (responseActions, actionIndex) => {
				let action = _.cloneDeep(responseActions[actionIndex]);
				responseActions.push({
					...action,
					label: ""
				});
				return responseActions;
			}

			const handleReorderResponseActions = (oldIndex) => {
				Swal.fire({
					title: 'Reorder actions',
					text: 'Move  to  S. No.',
					input: 'text',
					inputAttributes: {
						autocapitalize: 'off',
						required: 'required'

					},
					showCancelButton: true,
					confirmButtonText: 'Reorder',
					showLoaderOnConfirm: true,
					inputValidator: (value) => {
						let parsedValue = parseInt(value);
						if (!parsedValue) {
							return 'Please enter a number';
						}
						if (parsedValue) {
							if (value > fEPResponseActions.length || parseInt(value) === 0) {
								return 'Please enter a value between 1 and ' + fEPResponseActions.length;
							}
						}
					},
					preConfirm: (newIndex) => {
						let responseActions = _.cloneDeep(fEPResponseActions);
						let reorderedArray = reorderResponseActions(responseActions, newIndex, oldIndex)
						setFEPResponseActions(reorderedArray);
					}
				});
			};

			const handleCloneResponseAction = (actionIndex) => {
				swalBootstrapButtonsDangerousQuestion
					.fire({
						title: 'Are you sure?',
						text: 'Response action will be cloned',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: 'Yes',
						cancelButtonText: 'No',
						reverseButtons: true,
					})
					.then(async (result) => {
						if (result.value) {
							let responseActions = _.cloneDeep(fEPResponseActions);
							let newArray = cloneResponseAction(responseActions, actionIndex);
							setFEPResponseActions(newArray);
						}
					});
			};

			return (
				<>
					<Card className="p-1" style={{ backgroundColor: palette.blueseq[1], borderColor: palette.blueseq[1] }}>
						{/* Response additional actions */}
						<Row className="mt-2 mb-2">
							<Col xs="auto">
								<Tooltip title="collapse/expand">
									<IconButton
										component="span"
										className="p-0"
										onClick={handleActionsCollapseBtnClick}
									>
										{areFEPResponseActionsExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
									</IconButton>
								</Tooltip>
							</Col>
							<Col xs="auto" className="m-0 d-flex align-items-center">
								<AddToolTip
									placement="top-start"
									title={Verbs.title.response[fEPRequestType].actions}
								>
									<InputLabel className="m-0">Actions</InputLabel>
								</AddToolTip>
							</Col>
							<Col xs="auto" className="d-inline-flex align-items-center text-right">
								<Switch
									// className="d-inline-flex align-items-center"
									onChange={(checked) =>
										handleAreFEPResponseActionsEnabled(checked)
									}
									checked={areFEPResponseActionsEnabled}
									height={20}
									width={40}
									disabled={!isEditable}
									onColor={palette.primary}
								/>
							</Col>
						</Row>
						<Collapse in={areFEPResponseActionsExpanded} timeout="auto">
							{/* constains list of response actions */}
							{fEPResponseActions.map((item, index) => {
								return (
									<ResponseAction
										key={index}
										actionItem={item}
										index={index}
										handleFEPResponseActionsUpdate={handleFEPResponseActionsUpdate}
										handleFEPHTTPResponseActionsUpdate={handleFEPHTTPResponseActionsUpdate}
										handleFEPJMSResponseActionsUpdate={handleFEPSQSorJMSResponseActionsUpdate}
										handleFEPTibcoResponseActionsUpdate={handleFEPTibcoResponseActionsUpdate}
										handleFEPKinesisResponseActionsUpdate={handleFEPKinesisResponseActionsUpdate}
										handleFEPExecuteJSResponseActionsUpdate={handleFEPExecuteJSResponseActionsUpdate}
										handleFEPPropertiesResponseActionsUpdate={handleFEPPropertiesResponseActionsUpdate}
										handleFEPDatabaseResponseActionsUpdate={handleFEPDatabaseResponseActionsUpdate}
										handleFEPSQSResponseActionsUpdate={handleFEPSQSorJMSResponseActionsUpdate}
										handleFEPSMTPResponseActionsUpdate={handleFEPSMTPResponseActionsUpdate}
										handleRemoveResAction={handleRemoveResAction}
										handleAddResAction={handleAddResAction}
										isEditable={isEditable}
										areFEPResponseActionsEnabled={areFEPResponseActionsEnabled}
										actionsLength={fEPResponseActions.length}
										mASSLCertAliases={mASSLCertAliases}
										isVirtualServiceSubmitted={isVirtualServiceSubmitted}
										handleReorderResponseActions={handleReorderResponseActions}
										handleCloneResponseAction={handleCloneResponseAction}
									/>
								);
							})}
							{hasExceededResponseActionRows(fEPResponseActions.length) && (
								<Row className="text-center mt-3">
									<Col className="text-center">
										<span className="text-danger" style={{ fontSize: 15 }}>
											<FaExclamationCircle
												className="mr-2"
												size="1.1rem"
												style={{ marginBottom: '0.1rem' }}
											/>
											{Verbs.validationMessages.responseActionListLengthInvalid}
										</span>
									</Col>
								</Row>
							)}
						</Collapse>
					</Card>
				</>
			);
		};

		return (
			<Col>
				{/* Response column */}
				<Row className="d-flex align-items-center">
					<Col xs={3}>
						<Typography
							className="font-weight-bold d-flex align-items-center"
							variant="h5"
							gutterBottom
						>
							Response
						</Typography>
					</Col>
				</Row>
				{renderFEPResponseActions()}
				<div hidden={(fEPRequestType !== 'HTTP')}>
					<Divider className="mt-3" />
					{/* Response status */}
					<Row className="mt-4 d-flex align-items-center">
						<Col xs="auto" className="m-0 d-flex align-items-center">
							<AddToolTip placement="top-start" title={Verbs.title.status}>
								<InputLabel>Status</InputLabel>
							</AddToolTip>
						</Col>
						<Col xs="auto">
							<Input
								invalid={isResponseStatusInvalid(fEPResponseStatus)}
								type="number"
								min={100}
								max={599}
								bsSize="sm"
								name="responseStatus"
								value={fEPResponseStatus}
								onChange={(e) => handleFEPResponseStatusUpdate(e)}
							></Input>
						</Col>
						<Col xs="auto" className="m-0 border-left border-dark">
							<AddToolTip
								placement="top-start"
								title={Verbs.title.responseTemplate}
							>
								<InputLabel className="m-0">Template</InputLabel>
							</AddToolTip>
						</Col>
						<Col className="text-right" xs="auto">
							<Switch
								className="d-flex align-items-center"
								onChange={(checked) =>
									handleIsFEPResponseTemplatedChange(checked)
								}
								checked={isFEPResponseTemplated}
								height={20}
								width={40}
								disabled={!isEditable}
								onColor={palette.primary}
							/>
						</Col>
						<Col xs="auto" className="m-0 border-left border-dark">
							<AddToolTip
								placement="top-start"
								title={Verbs.title.enableResponseDelay}
							>
								<InputLabel className="m-0">Delay</InputLabel>
							</AddToolTip>
						</Col>
						<Col>
							<Switch
								className="d-flex align-items-center"
								onChange={(checked) =>
									handleIsFEPResponseDelayEnabledChange(checked)
								}
								checked={isFEPResponseDelayEnabled}
								height={20}
								width={40}
								onColor={palette.primary}
							/>
						</Col>
					</Row>
					<Row className="mt-3" hidden={!isFEPResponseDelayEnabled}>
						<Col xs="auto">
							<AddToolTip placement="top-start" title={Verbs.title.responseDelay}>
								<InputLabel>Delay value</InputLabel>
							</AddToolTip>
						</Col>
						<Col>
						<Input
							type={isFEPResponseTemplated ? "text" : "number"}
							min={0}
							max={60000}
							bsSize="sm"
							value={fEPResponseDelay}
							onChange={(e) => handleFEPResponseDelayUpdate(e)}>
						</Input>
						</Col>
					</Row>
					{renderFEPResponseHeaders()}
					{/* Response body */}
					<Divider className="mt-3" />
					<Row className="mt-3">
						<Col xs="auto">
							<AddToolTip placement="top-start" title={Verbs.title.responseBody}>
								<InputLabel>Body</InputLabel>
							</AddToolTip>
						</Col>
					</Row>
					<Row className="mt-2">
						<Col>
							<Input
								type="textarea"
								bsSize="sm"
								maxLength={MAX_BODY_RESPONSE_SIZE}
								rows="4"
								name="responseBody"
								value={fEPResponseBody}
								onChange={(e) => handleFEPResponseBodyUpdate(e)}
							></Input>
						</Col>
					</Row>
				</div>
			</Col>
		);
	};

	const renderFocussedEndpoint = () => {
		const handleFEPNextButtonClick = () => {
			// TODO merge all validations and use single array to show all validation failues
			let valid = true;
			let validationMessages = [];
			// missing Actions will be an array of arrays, for e.g.
			// for three actions, it will be [['protocolToProxy'],[],['protocolToProxy']]
			// above example means action 1 and 3 has these fields missing. It will only be checked if valid === 'actionValidationFailed'
			let validationMissingActions = [];

			setIsVirtualServiceSubmitted(true);
			// TO DO:Merge both validations

			if (isPathInvalid(fEPRequestPath[0].value) && isJMSPathInvalid(fEPRequestPath[0].value) && isTibcoPathInvalid(fEPRequestPath[0].value) && isCometDPathInvalid(fEPRequestPath[0].value)) {
				valid = false;
				validationMessages.push(Verbs.validationMessages.pathInvalid);
			}
			if (hasExceededQueryRows(fEPRequestQueryParamsList.length)) {
				valid = false;
				validationMessages.push(
					Verbs.validationMessages.queryListLengthInvalid
				);
			}
			if (isResponseStatusInvalid(fEPResponseStatus)) {
				valid = false;
				validationMessages.push(
					Verbs.validationMessages.responseStatusInvalid
				);
			}
			if (hasExceededRequestHeaderRows(fEPRequestHeadersList.length)) {
				valid = false;
				validationMessages.push(
					Verbs.validationMessages.headerListLengthInvalid
				);
			}
			if (hasExceededResponseHeaderRows(fEPResponseHeadersList.length)) {
				valid = false;
				validationMessages.push(
					Verbs.validationMessages.responseHeaderListLengthInvalid
				);
			}
			if (hasExceededResponseActionRows(fEPResponseActions.length)) {
				valid = false;
				validationMessages.push(
					Verbs.validationMessages.responseActionListLengthInvalid
				);
			}
			fEPResponseActions.map((actionItem, index) => {
				let missingFieldActionArr = [];
				if (parseInt(actionItem.delaySeconds) > MAX_ACTION_DELAY_SECONDS) {
					missingFieldActionArr.push(`${actionItem.actionId} contains incorrect delay value. Maximum allowed delay value is ${MAX_ACTION_DELAY_SECONDS} seconds`);
					valid = 'actionValidationFailed';
				}
				switch (actionItem.actionType) {
					case 'httpForward':
						if (actionItem.templated) {
							if (!actionItem.actionData.httpMethod || !actionItem.actionData.httpMethod.length) {
								missingFieldActionArr.push('Http Method Template');
								valid = 'actionValidationFailed';
							}
							else if (!isMethodTemplateValid(actionItem.actionData.httpMethod)) {
								missingFieldActionArr.push(Verbs.validationMessages.methodTemplateInvalid);
								valid = 'actionValidationFailed';
							} else {
								break;
							}
						}
						break;
					case 'jmsWriteTopic':
					case 'jmsWriteQueue':
						if (!actionItem.actionData.protocolProxyURL) {
							missingFieldActionArr.push(' Protocol proxy base url');
							valid = 'actionValidationFailed';
						}
						if (!actionItem.actionData.qOrTName) {
							if (actionItem.actionType === 'jmsWriteTopic') {
								missingFieldActionArr.push(' Topic Name');

							}
							else {
								missingFieldActionArr.push(' Queue Name');
							}
							valid = 'actionValidationFailed';
						}
						break;
					case 'tibcoWriteTopic':
					case 'tibcoWriteQueue':
						if (!actionItem.actionData.protocolProxyURL) {
							missingFieldActionArr.push(' Protocol proxy base url');
							valid = 'actionValidationFailed';
						}
						if (!actionItem.actionData.qOrTName) {
							if (actionItem.actionType === 'tibcoWriteTopic') {
								missingFieldActionArr.push(' Topic Name');

							}
							else {
								missingFieldActionArr.push(' Queue Name');
							}
							valid = 'actionValidationFailed';
						}
						break;
					case 'kinesisWrite':
						if (!actionItem.actionData.protocolProxyURL) {
							missingFieldActionArr.push(' Protocol proxy base url');
							valid = 'actionValidationFailed';
						}
						if (!actionItem.actionData.streamName) {
							missingFieldActionArr.push(' Stream name');
							valid = 'actionValidationFailed';
						}
						if (!actionItem.actionData.partitionKey) {
							missingFieldActionArr.push(' Partition key');
							valid = 'actionValidationFailed';
						}
						break;
					case 'executeJS':
						if (!actionItem.actionData.script) {
							missingFieldActionArr.push(' Script');
							valid = 'actionValidationFailed';
						}
						break;
					case 'sqsQueue':
						if (!actionItem.actionData.protocolProxyURL) {
							missingFieldActionArr.push(' Protocol proxy base url');
							valid = 'actionValidationFailed';
						}
						if (!actionItem.actionData.qOrTName) {
							missingFieldActionArr.push(' Queue Name');
							valid = 'actionValidationFailed';
						}
						break;
					case 'ibmmqWriteQueue':
						if (!actionItem.actionData.protocolProxyURL) {
							missingFieldActionArr.push(' Protocol proxy base url');
							valid = 'actionValidationFailed';
						}
						if (!actionItem.actionData.qOrTName) {
							missingFieldActionArr.push(' Queue Name');
							valid = 'actionValidationFailed';
						}
						break;
					case 'smtp':
						if (!actionItem.actionData.fromAddress) {
							missingFieldActionArr.push('From Address');
							valid = 'actionValidationFailed';
						}
						if (!actionItem.actionData.toAddress) {
							missingFieldActionArr.push('To Address');
							valid = 'actionValidationFailed';
						}
						break;
				}
				validationMissingActions.push(missingFieldActionArr);
				return null;
			});
			if (valid == 'actionValidationFailed') {
				// e.g. validationMissingActions: [['field1','field2'],[],['field3', 'field4']]
				let titleMessage = ' ';
				validationMissingActions.forEach((missingActionItem, index) => {
					if (missingActionItem.length !== 0) {
						titleMessage = titleMessage + '<br><b>' + (index + 1) + '</b> => ' + missingActionItem;
					}
				});

				Swal.fire({
					icon: 'warning',
					title: 'Following fields are missing',
					html: `<div style="text-align:left">${titleMessage}<div>`
				});
			}
			else if (valid) {
				let runValidation = true;
				if (runValidation === true) {

					runValidate();
				}
				else {
					saveEndpointOnNext();
				}
			}

			else {
				let validationMessagesHTML = '<div style="text-align:left">';
				validationMessages.forEach(message => {
					validationMessagesHTML = validationMessagesHTML + '<br/><li>' + message + '</li>';
				});
				validationMessagesHTML += '<div>';
				Swal.fire({
					icon: 'warning',
					title: 'Please correct highlighted errors before proceeding',
					html: validationMessagesHTML
				});
			}
		};
		const handleFEPResetButtonClick = () => {
			swalBootstrapButtonsDangerousQuestion
				.fire({
					title: 'Are you sure?',
					text:
						'Any changes made to this endpoint will be replaced with default values',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					cancelButtonText: 'No',
					reverseButtons: true,
				})
				.then(async (result) => {
					if (result.value) {
						const newPair = _.cloneDeep(default_vs.data.pairs[0]);
						newPair.request.query = { '': [{ value: '', matcher: 'exact' }] };
						newPair.request.headers = {
							Svhost: [{ value: sVHostHeader, matcher: 'glob' }],
							'': [{ value: '', matcher: 'exact' }],
						};
						newPair.request.body = [{ value: '', matcher: 'exact' }];
						newPair.response.headers = { '': [''] };
						newPair.response.actions = [_.cloneDeep(default_response_action)];
						newPair.tableData = { id: focussedEndpoint.tableData.id };
						handleSetFEP({ ...newPair }, false);
						setEndpointPairs(
							endpointPairs.map((pair) => {
								if (pair.tableData.id === focussedEndpoint.tableData.id) {
									return newPair;
								} else {
									return pair;
								}
							})
						);
					}
				});
		};
		const handleFEPDeleteButtonClick = () => {
			handleDeleteEndpointClick(focussedEndpoint);
		};
		const handleFEPCancelButtonClick = () => {
			swalBootstrapButtonsDangerousQuestion
				.fire({
					title: 'Are you sure?',
					text: 'Any changes made to this endpoint will be discarded',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Yes',
					cancelButtonText: 'No',
					reverseButtons: true,
				})
				.then(async (result) => {
					if (result.value) {
						handleSetFEP(endpointPairs[focussedEndpoint.tableData.id], false);
						setIsAnEndpointFocussed(false);
					}
				});
		};
		return (
			<div ref={endpointRef}>
				<Collapse in={isAnEndpointFocussed}>
					<Form className="mt-3 p-2">
						<fieldset disabled={!isEditable}>
							<Card
								className="p-3"
								style={{ backgroundColor: palette.blueseq[0] }}
							>
								<Row>
									<Col>
										<Typography
											className="font-weight-bold"
											variant="h4"
											gutterBottom
										>
											{`${fEPRequestType} - ${fEPRequestPath[0].value}`}
										</Typography>
									</Col>
								</Row>
								<Divider />
								<Row className="mt-2 mb-2 d-flex align-items-center">
									<Col xs="1">
										<AddToolTip
											placement="top-start"
											title={Verbs.title.label}
										>
											<InputLabel
												className="m-0 font-weight-bold"
											> Label
											</InputLabel>
										</AddToolTip>
									</Col>
									<Col xs="5">
										<Input
											placeholder="Ex: ProviderService_Interface_Env_UniqueName"
											bsSize="sm"
											maxLength={60}
											name="endpointLabel"
											value={fEPLabel}
											onChange={(e) => handleFEPLabelUpdate(e)}
										></Input>
									</Col>
									<Col xs="6" className="d-flex align-items-center justify-content-end">
										<AddToolTip
											placement="top-start"
											title={Verbs.title.endpointId}
											hidden={!focussedEndpoint.endpointId}
										>
											<InputLabel
												className="m-0 font-weight-bold"
												align="right"
											> {`ID       ${focussedEndpoint.endpointId}`}
											</InputLabel>
										</AddToolTip>
									</Col>
								</Row>

								<Row className="mt-2 mb-2 d-flex align-items-center">
									<Col xs="1">
										<AddToolTip
											placement="top-start"
											title={Verbs.title.description}
										>
											<InputLabel style={{ whiteSpace: 'nowrap' }}
												className="m-0 font-weight-bold"
											> Description
											</InputLabel>
										</AddToolTip>
									</Col>
									<Col xs="5">
										<Input
											placeholder="Describe the purpose of the stub."
											type='textarea'
											name="descriptionLabel"
											value={fEPDescription}
											onChange={(e) => handleFEPDescription(e)}
										></Input>
									</Col>
								</Row>

								<Row className="mt-3">
									{/* Request column */}
									<Col>
										<Typography
											className="font-weight-bold"
											variant="h5"
											gutterBottom
										>
											Request matchers
										</Typography>
										{/* Request type */}
										<Row className="mt-4">
											<Col xs="auto">
												<AddToolTip
													placement="top-start"
													title={Verbs.title.requestType}
												>
													<InputLabel className="asterisk">
														Request Type
													</InputLabel>
												</AddToolTip>
											</Col>
										</Row>
										<Row className="mt-2">
											<Col>
												<Input
													type="select"
													name="requestTypeSelect"
													bsSize="sm"
													value={fEPRequestType}
													onChange={(e) =>
														handleFEPRequestTypeUpdate(e.target.value)
													}
												>
													{request_types.map(({ label, value }) => {
														return <option key={value} value={value}>{label}</option>;
													})}
												</Input>
											</Col>
										</Row>
										{renderFEPRequestForm()}
									</Col>
									<Divider orientation="vertical" flexItem />
									{renderFEPResponseForm()}
								</Row>
								<Row className="mt-3" hidden={!isEditable}>
									<Col></Col>
									<Col xs="auto" className="text-right pr-0">
										<AddToolTip
											placement="top-start"
											title={Verbs.title.btnFEPCancel}
										>
											<Button
												color="secondary"
												className="mr-3"
												onClick={handleFEPCancelButtonClick}
											>
												Cancel
											</Button>
										</AddToolTip>
									</Col>
									<Col xs="auto" className="text-right pl-0 pr-0" hidden={true || (endpointPairs.length === 1)}>
										<AddToolTip
											placement="top-start"
											title={Verbs.title.btnFEPDelete}
										>
											<Button
												color="danger"
												className="mr-3"
												onClick={handleFEPDeleteButtonClick}
											>
												Delete
											</Button>
										</AddToolTip>
									</Col>
									<Col xs="auto" className="text-right pl-0 pr-0">
										<AddToolTip
											placement="top-start"
											title={Verbs.title.btnFEPReset}
										>
											<Button
												color="warning"
												className="mr-3"
												onClick={handleFEPResetButtonClick}
											>
												Reset
											</Button>
										</AddToolTip>
									</Col>
									<Col xs="auto" className="text-right pl-0">
										<AddToolTip
											placement="top-start"
											title={Verbs.title.btnFEPNext}
										>
											<Button
												color="primary"
												onClick={handleFEPNextButtonClick}
											>
												Next
											</Button>
										</AddToolTip>
									</Col>
								</Row>
							</Card>
						</fieldset>
					</Form>
				</Collapse>
			</div>
		);
	};
	const renderFilePreview = () => {
		try {
			const collatedVS = collateVirtualService(endpointPairs, sVHostHeader);
			return (
				<FilePreview
					virtualService={collatedVS}
				></FilePreview>
			);
		} catch (error) {
			// TODO handle the error
			console.log(error);
		}

	};
	const toggleVSRevision = () => {
		setVSRevision(!vSRevision);
	};
	const getVSData = () => {
		setLoading(true);
		simulationService
			.getSVHostHeader({ teamApplication: team_application_id })
			.then((response1) => {
				setSVHostHeader(response1.data);
				if (stub_id && s3_path) {
					simulationService
						.getS3ObjectVerions({
							prefix: s3_path,
						})
						.then((response2) => {
							setS3VersionDetails(response2.data);
							setSelectedVersion(response2.data.result[0].version);
							simulationService
								.getRawFileData({ s3_path: s3_path })
								.then((response) => {
									setLoading(false);
									if (response.data.code === 200) {
										try {
											let virtualService = response.data.data;
											let processedVirtualService = processVirtualService(
												virtualService
											);
											setEndpointPairs(processedVirtualService.data.pairs);
										} catch (error) {
											// TODO handle this error
											console.log(error);
										}

									} else {
										setLoading(false);
										Swal.fire(
											'Error!',
											'Error in processing the file !',
											'error'
										);
									}
								});
						});
				} else {
					setLoading(false);
					let processedVirtualService = _.cloneDeep(default_vs_clone);
					setEndpointPairs(processedVirtualService.data.pairs);
					handleSetFEP(processedVirtualService.data.pairs[0], true);
				}
			})
			.catch(() => {
				setLoading(false);
				Swal.fire(
					'Error !',
					'Could not fetch data due to an internal error. Please retry.',
					'error'
				);
			});
	};
	const [mASSLCertAliases, setMASSLCertAliases] = useState([]);
	const getMASSLCertAliases = () => {
		simulationService.getSVHost({ teamApplication: team_application_id })
			.then((response) => {
				workspaceSettingsService.getMASSLHTTPFwdClientsCerts(response.data)
					.then((response2) => {
						setMASSLCertAliases(response2.data);
					})
					.catch((error) => {
						// TODO handle error
						console.log('Error occured when fetching massl certificate aliases', error);
					});
			});
	};

	useEffect(() => {
		getVSData();
		getMASSLCertAliases();
		if (endpointPairs.length === 1 && endpointRef.current) {
			scrollToRef(endpointRef);
		} else {
			window.scrollTo(0, 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			getVSData();
		}
		return function cleanup() {
			mounted = false;
		};
	}, [vSRevision]);

	useEffect(() => {
		if (filePreviewToggle) {
			setTimeout(() => scrollToRef(filePreviewRef), 300);
		}
	}, [filePreviewToggle]);

	return (
		<div>
			<Container>
				<div className="white mt-3">
					{renderTitle()}
					<Divider></Divider>
					{loading ? (
						<Jumbotron className="text-center">
							<Spinner className="p-5" color="primary"></Spinner>
						</Jumbotron>
					) : (
						<>
							{renderVersionSelection()}
							{renderEndpoints()}
							{renderFocussedEndpoint()}
							<Row className="mt-3" hidden={!isEditable || isAnEndpointFocussed}>
								<Col></Col>
								<Col xs="auto" className="text-right pr-0">
									<AddToolTip
										placement="top-start"
										title={Verbs.title.btnVSCancel}
									>
										<Button
											color="secondary"
											className="mr-3"
											onClick={handleCancelClick}
										>
											Cancel
										</Button>
									</AddToolTip>
								</Col>
								<Col xs="auto" className="text-right pl-0 pr-0">
									<AddToolTip
										placement="top-start"
										title={Verbs.title.btnVSPreview}
									>
										<Button
											color="info"
											className="mr-3"
											onClick={handlePreviewClick}
										>
											Preview
										</Button>
									</AddToolTip>
								</Col>

								<Col xs="auto" className="text-right pl-0">
									<AddToolTip placement="top-start" title={Verbs.title.btnSaveVS}>
										<Button color="primary" onClick={handleSaveClick}>
											Save virtual service
										</Button>
									</AddToolTip>
								</Col>
							</Row>
							<div ref={filePreviewRef}>
								<Collapse in={filePreviewToggle && !isAnEndpointFocussed}>
									{renderFilePreview()}
								</Collapse>
							</div>
						</>
					)}
				</div>
				<Row></Row>
			</Container>
			{/* TODO add render FEP function */}

			{activateCJV === false ? <></> :
				<Collapse in={isOpen} className='mt-2'>
					<Container>
						<div className='whiteTable mt-3'>
							<h3>Customer Journey Virtualisation scenarios(CJV)</h3>
							{cjvMaterialLoader === false ? (<>
								<div className="mt-2">
									<MaterialTable

										title={<h5>{focussedEndpoint.request.method[0].value || ' '}{' '}{focussedEndpoint.request.path[0].value || ''}</h5>}
										columns={CJVColumns}
										data={cjvFilesListS3}
										actions={cjvActions}
										options={{
											rowStyle: (rowData) => (
												{
													backgroundColor:
														cjvFocussedEndpoint &&
															cjvFocussedEndpoint.tableData.id === rowData.tableData.id
															? palette.blueseq[0]
															: '#FFF',
												}),
											headerStyle: {
												fontWeight: 'bold',
												backgroundColor: palette.blueseq[3],
											},
											paging: endpointPairs.length > 5,
											actionsColumnIndex: -1,
										}}

										onRowClick={(event, rowData) => handelCJVEndPoint(event, rowData)}
									/>
								</div>

							</>) :

								<Jumbotron className="text-center">
									<Spinner className="p-5" color="primary"></Spinner>
								</Jumbotron>}
							{(isCJVFlowOpen === true) ? (<div><Collapse in={isCJVFlowOpen} className="mt-3">


								<CJVScenarios
									showCjvLoader={showCjvLoader}
									vsName={virtualServiceName}
									setDefaultCJVInVS={setDefaultCJVInVS}
									isCJVGenerated={isCJVGenerated}
									collapseEverything={collapseEverything}
									sVHostHeader={sVHostHeader}
									stubS3Path={s3_path}
									toggleVSRevision={toggleVSRevision}
									cjvFilename={cjvFilename}
									defaultCJVScenario={defaultScenario}
									endPointIndexAndId={endPointIndexAndId}
									goToManagePage={goToManagePage}
									handleSaveVSAfterCJV={handleSaveVSAfterCJV}
									removeDefaultCJV={removeDefaultCJV}
								/>


							</Collapse></div>) : (showCjvDiagramLoader === true ? <Jumbotron className="text-center">
								<Spinner className="p-5" color="primary"></Spinner>
							</Jumbotron> : <></>)
							}

						</div>

					</Container>

				</Collapse>}
		</div>
	);
}
